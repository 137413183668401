import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import CoqIcon from "../../../../static/Coq.svg"

import {
  ProductImageSection,
  ProductInfoBox,
  ProductRightInfoSection,
  ProductSummaryTop,
  ProductSummaryBottom,
  ProductBottomInfo,
  DesktopProductBottomPriceInfo,
  MobileProductBottomPriceInfo,
  ProductBottomCartButtonBox,
  ProductInfoBottom,
  DesktopPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  ProductAlmaDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  Select,
  LayerDisabled,
  MobileSelect,
  UserTypeWrapper,
  ChooseGenderText,
  LyneupContainer,
  GenderSection,
  GenderSelector,
  GenderSelectorBox,
  GenderSelectorImage,
  GenderSelectorText,
  GenderArrow,
  ColorSection,
  ColorSelector,
  ColorSelectorBox,
  ColorSelectorImage,
  ColorSelectorText,
  SizeSelectorTitle,
  SizeSection,
  SizeSelector,
  SizeSelectorText,
  SizeSelectorMiddleText,
  AddToCartButton,
  LyneupErrorBox,
  ErrorBox,
  LyneupUserSection,
  LyneUpSectionBgImage,
  LyneupUserTextBox,
  LyneUpTextContainer,
  LyneupUserLogoImage,
  LyneupSectionTitle,
  LyneupSectionText,
  LyneupBenefitsSection,
  LyneupBenefitsBox,
  LyneupBenefitsImage,
  LyneupBenefitsText,
  LyneupExpertSection,
  LyneupCircleSection,
  LyneupDesktopSection,
  LyneupDesktopImageSection,
  LyneupDesktopImage,
  LyneupDesktopTextImage,
  LyneupDesktopTextSection,
  LyneupSpineCircleContainer,
  LyneupDesktopSpineCircleSection,
  LyneupSpineCircleImage,
  EnterPriseSectionRedirect,
  LyneupTestimonials,
  LyneupTestimonialImage,
  LyneupTestimonialText,
  LyneUpLandingTopButton,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  LyneupSizeErrorBox,
  ProductStrikePrice,
  ProductDiscountPrice,
  LyneProductStrikePrice,
  LyneProductDiscountPrice,
  LyneComboSection,
  LyneComboWrapper,
  LyneComboBox,
  LyneComboBoxTagWrapper,
  LyneComboBoxTag,
  LyneComboBoxTitle,
  LyneComboBottomSection,
  LyneComboLeftSection,
  LyneComboBoxImage,
  LyneCheckImage,
  LyneComboRightBox,
  ClockImage,
  LyneProductRegularPrice,
} from "../../../components/Products/styled"

import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductUserType from "../../../components/Products/ProductUserType"
import ProductColorBox from "../../../components/Products/ProductColorBox"
import ProductSizeDetail from "../../../components/Products/ProductSizeDetail"
import { ProductSizeVariationBoxWrapper } from "../../../components/Products/ProductSizeVariationBox"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import {
  CapitalizeFirstLetter,
  colors,
  genders,
  LyneUp2BImageSliders,
  colorsReverse,
  genderReverse,
} from "../../../utils/lyneUphelpers"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { navigate } from "@reach/router"
import PriceDetails from "../../../components/Products/PriceDetails"
import LyneupSizeGuidePopup from "../../../components/Products/LyneupSizeGuidePopup"
import MobileAddToCartPopup from "../../../components/Products/MobileAddToCartPopup"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addLyneHomeUrl,
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import Loader from "react-loader-spinner"
import { FaArrowRight } from "react-icons/fa"
import LyneupSizeGuideModal from "../../../components/Products/LyneupSizeGuideModal"
import LyneupBProductTopSection from "../../../components/Products/LyneupBProductTopSection"
import LyneHomeCrossProductSection from "../../../components/Products/LyneHomeCrossProductSection"
import {
  SizeSelectorLyneUpFemale,
  SizeSelectorLyneUpMale,
} from "../../../components/SizeSelector"
import ResultHomeSection from "../../../components/Layout/ResultHomeSection"
import LyneHomeInnovationSection from "../../../components/Products/LyneHomeInnovationSection"
import TvAdsSection from "../../../components/Layout/TvAdsSection"
import TestimonialHomeSection from "../../../components/Layout/TestimonialHomeSection"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import {
  getPrice,
  monthlyPrice,
  navigateToCart,
} from "../../../utils/navigateToCart"
import ProductKlarnaSection from "../../../components/Products/ProductKlarnaSection"
import ProductPriceInformationSection from "../../../components/Products/ProductPriceInformationSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import LyneupHighlightSection from "../../../components/Products/LyneupHighlightSection"
import LyneupProductInformationSection from "../../../components/Products/LyneupProductInformationSection"
import LyneupTechnologySection from "../../../components/Products/LyneupTechnologySection"
import LyneupUseSection from "../../../components/Products/LyneupUseSection"
import LyneupMedicalSection from "../../../components/Products/LyneupMedicalSection"
import LyneupReviewSection from "../../../components/Products/LyneupReviewSection"
import TestimonialSection from "../../../components/Layout/TestimonialSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"

const LyneUp = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lyneup"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({
    Gender: process.env.GATSBY_CURRENCY_TYPE === "gbp" ? "Female" : "",
    Color: process.env.GATSBY_CURRENCY_TYPE === "gbp" ? "BLACK" : "",
    Size: "",
  })
  const [openModal, setopenModal] = useState(false)
  const [openSizeModal, setopenSizeModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [openMobileAddToCartModal, setopenMobileAddToCartModal] = useState(
    false
  )
  const [activeFlag, setactiveFlag] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [menSizeParameters, setmenSizeParameters] = useState({
    Age: "",
    Height: "",
    Weight: "",
  })

  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [activeGenderIndex, setactiveGenderIndex] = useState(null)
  const [activeColorIndex, setactiveColorIndex] = useState(null)
  const [sizeSelector, setsizeSelector] = useState("calculate")
  const [openSizeGuideModal, setopenSizeGuideModal] = useState(false)
  const [recommendation, setRecommendation] = useState({})
  const [discountPrice, setdiscountPrice] = useState(
    process.env.GATSBY_CURRENCY_TYPE === "gbp"
      ? 84
      : process.env.GATSBY_CURRENCY_TYPE === "eur"
      ? 99
      : ""
  )
  const [selectProductType, setselectProductType] = useState("Seul")

  const handleQuantityChange = event => {
    if (event.target.value > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
    setselectedVariantQuantity(event.target.value)
  }

  const handleParamStrings = (selectedOptions, productType) => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    if (productType) {
      paramString = paramString + "&Offer" + "=" + productType
    }
    isBrowser && history.replaceState(null, null, paramString)
    // navigate(`${currUrl}${paramString}`)
  }

  const removeErrors = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      let selectedOptions = { ...selectOptions }

      if (data.recommendation.size?.length) {
        selectedOptions["Size"] = data.recommendation.size
        setselectOptions(selectedOptions)

        handleChangeSelectOptions({
          target: { name: "Size", value: data.recommendation.size },
        })

        setRecommendation(data)

        if (selectOptions["Gender"] === "Male") {
          setmenSizeParameters(data)
        }

        seterrorSize("")
      } else {
        seterrorSize(
          intl
            .formatMessage({
              id: "size_selector.lyneup_size_error_message",
            })
            .replace(
              "/shop/",
              addLyneHomeUrl(intl.locale, selectOptions["Gender"])
            )
        )
      }
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    setselectOptions(selectOptions => {
      let selectedOptions = { ...selectOptions }

      if (target.name === "Color") {
        selectedOptions[target.name] = colors[intl.locale][target.value]
      } else if (target.name === "Gender") {
        selectedOptions[target.name] = genders[intl.locale][target.value]
        seterrorSize("")
        selectedOptions["Size"] = ""
        if (!selectOptions["Color"]) {
          selectedOptions["Color"] = "BLACK"
        }
      } else if (target.name === "Size") {
        if (target.value) {
          selectedOptions[target.name] = target.value
        } else {
          selectedOptions[target.name] = ""
        }
      }

      removeErrors(selectedOptions)
      handleParamStrings(selectedOptions, selectProductType)

      if (Object.keys(selectedOptions).length === 3) {
        const selectedVariant = client.product.helpers.variantForOptions(
          product,
          selectedOptions
        )
        if (selectedVariant) {
          setselectedVariant(selectedVariant)
          setErrors({ exist: false, message: [] })
        } else setselectedVariant(null)
      }
      return selectedOptions
    })
  }

  const handleGender = index => {
    handleChangeSelectOptions({
      target: { name: "Gender", value: index },
    })
  }

  const handleColor = index => {
    handleChangeSelectOptions({
      target: { name: "Color", value: index },
    })
  }

  const sizeSelectorFunc = value => {
    if (value === "calculate") {
      setopenMobileAddToCartModal(true)
    } else {
      setopenSizeGuideModal(true)
    }
    setsizeSelector(value)
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "size_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error" }),
        ]
    }

    removeErrors(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    let flatInput = recommendation?.inputs
      ? Object.keys(recommendation.inputs).map(key => ({
          key: key,
          value: `${recommendation.inputs[key]}`,
        }))
      : []

    let flatRecommendation = recommendation?.recommendation
      ? Object.keys(recommendation.recommendation).map(key => ({
          key: key,
          value: `${recommendation.recommendation[key]}`,
        }))
      : []
    // prepare attributes
    let attributes = [...flatInput, ...flatRecommendation]

    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        attributes,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    let options = { ...selectOptions }
    if (genderParam) {
      options.Gender = genderParam
      options.Color = "BLACK"
      setactiveGenderIndex(options.Gender)
      setactiveColorIndex(options.Color)
    }

    if (colorParam) {
      options.Color = colorParam
      setactiveColorIndex(options.Color)
    }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    setselectOptions(options)
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
    $(".sticy-lyneup-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const returnSizeSelector = mobile => {
    return (
      <>
        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Male"}
          mobile={mobile}
          style={{ marginBottom: 0 }}
        >
          <SizeSelectorLyneUpMale
            setLoading={setLoading}
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Female"}
          mobile={mobile}
          style={{ marginBottom: 0 }}
        >
          <SizeSelectorLyneUpFemale
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        {errorSize && (
          <LyneupSizeErrorBox dangerouslySetInnerHTML={{ __html: errorSize }} />
        )}
      </>
    )
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const getDiscountPrice = event => {
    if (selectedVariantQuantity > 1) {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 74
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 89
          : ""
      )
    } else {
      setdiscountPrice(
        process.env.GATSBY_CURRENCY_TYPE === "gbp"
          ? 84
          : process.env.GATSBY_CURRENCY_TYPE === "eur"
          ? 99
          : ""
      )
    }
  }

  const handleProductType = productType => {
    let price = ""
    if (productType === "Pack") {
      price = lyneGroupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = lyneDiscountPrice()
      setselectedVariantQuantity(1)
    }

    let selectedOptions = { ...selectOptions }
    handleParamStrings(selectedOptions, productType)

    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )

    // setproductPrice(price)
    setselectProductType(productType)
  }

  let discountPercentage = 0.25

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant
        ? selectedVariant.price
        : product.priceRangeV2.maxVariantPrice.amount
    )

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // lyneDiscountPrice = Number(lyneDiscountPrice) - 15
    } else {
      // lyneDiscountPrice =
      //   Number(lyneDiscountPrice) - Number(lyneDiscountPrice) * Number(discountPercentage)
    }

    return getPrice(lyneDiscountPrice)
  }

  const lyneGroupPrice = () => {
    let lyneGroupPrice = selectedVariant
      ? selectedVariant.price
      : product.priceRangeV2.maxVariantPrice.amount

    lyneGroupPrice = Number(lyneGroupPrice) * 2

    return lyneGroupPrice
  }

  const lyneGroupDiscountPrice = () => {
    let LyneGroupDiscountPrice = lyneGroupPrice()

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      discountPercentage = 0.125
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 50
    } else {
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 60
    }

    return getPrice(LyneGroupDiscountPrice)
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    getDiscountPrice()
  }, [selectedVariantQuantity])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    if (process.env.GATSBY_ACTIVE_ENV === "eur") {
      if (intl.locale === "de" || intl.locale === "es")
        navigate(
          `/${process.env.GATSBY_ACTIVE_ENV}/${intl.locale}/shop/lyneup-2b/`
        )
    }
  }, [intl.locale])

  const handleHashScrolling = () => {
    $(document).ready(function() {
      if (window.location.hash && $(window.location.hash).length) {
        $("#footer").scrollTop(0)
        setTimeout(function() {
          $("html, body").animate(
            {
              scrollTop: $(window.location.hash).offset().top - 180,
            },
            1500
          )
        }, 1500)
      }
    })
  }

  useEffect(() => {
    isBrowser && handleHashScrolling()
  }, [])

  return (
    (intl.locale === "fr" || intl.locale === "en") &&
    data &&
    data.allDirectusLyneupTranslation.nodes.length > 0 && (
      <Fragment>
        <LyneupContainer>
          <SeoComp
            description={
              data.allDirectusSeoTranslation.nodes[0] &&
              data.allDirectusSeoTranslation.nodes[0].meta_description
            }
            title={
              data.allDirectusSeoTranslation.nodes[0] &&
              data.allDirectusSeoTranslation.nodes[0].meta_title
            }
            twitter_card={
              data.allDirectusSeoTranslation.nodes[0] &&
              data.allDirectusSeoTranslation.nodes[0].twitter_card
            }
            image={
              data.allDirectusSeoTranslation.nodes[0] &&
              data.allDirectusSeoTranslation.nodes[0].image &&
              data.allDirectusSeoTranslation.nodes[0].image.data &&
              data.allDirectusSeoTranslation.nodes[0].image.data.url
            }
            jsonLd="lyneup"
            hreflangPathname={{
              fr:
                process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr"
                  ? "shop/solutions-textiles"
                  : "shop/lyneup-test",
              en: `shop/lyneup-test`,
              de: `shop/lyneup-2b`,
              es: `shop/lyneup-2b`,
            }}
            noindex={true}
            nofollow={true}
          />
          <BottomStickyAddToCart
            title={data.allDirectusLyneupTranslation.nodes[0].product_title}
            image={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_title_logo_image
            }
            gender={genderReverse[intl.locale][selectOptions["Gender"]]}
            cart_text={
              !selectOptions["Gender"]
                ? intl.formatMessage({
                    id: "button_text_sticky_banner_no_gender",
                  })
                : !selectOptions["Size"]
                ? intl.formatMessage({
                    id: "button_text_sticky_banner_no_size",
                  })
                : data.allDirectusLyneupTranslation.nodes[0]
                    .product_add_to_cart_button
            }
            regularPrice={Number(
              selectedVariant
                ? selectedVariant.price
                : product.priceRangeV2.maxVariantPrice.amount
            ).toFixed(0)}
            handleAddTocart={selectedVariant ? handleAddTocart : null}
            active={activeFlag}
            setopenMobileAddToCartModal={
              IsMobile && selectOptions["Gender"] && !selectOptions["Size"]
                ? setopenMobileAddToCartModal
                : null
            }
            id="sticy-button"
            page="lyneup"
            quantity={selectedVariantQuantity}
            discountPrice={
              selectedVariantQuantity == 1
                ? lyneDiscountPrice()
                : lyneGroupDiscountPrice()
            }
          />
          <PriceDetails
            details={data.allDirectusLyneupTranslation.nodes[0].price_details}
            open={openModal}
            closeHandle={setopenModal}
          />
          {!IsMobile ? (
            <LyneupSizeGuidePopup
              data={data.allDirectusLyneupTranslation.nodes[0]}
              details={
                data.allDirectusLyneupTranslation.nodes[0].size_popup_text
              }
              gender={selectOptions["Gender"]}
              open={openSizeModal}
              closeHandle={setopenSizeModal}
            />
          ) : (
            <LyneupSizeGuideModal
              data={data.allDirectusLyneupNewPageTranslation.nodes[0]}
              details={
                data.allDirectusLyneupTranslation.nodes[0].size_popup_text
              }
              size_data={data.allDirectusLyneupTranslation.nodes[0].size}
              color_data={data.allDirectusLyneupTranslation.nodes[0].color}
              gender={selectOptions["Gender"]}
              open={openSizeGuideModal}
              closeHandle={setopenSizeGuideModal}
              addToCart={handleAddTocart}
              handleSelectOptions={handleChangeSelectOptions}
              addToCartText={
                data.allDirectusLyneupTranslation.nodes[0]
                  .product_add_to_cart_button
              }
              size={selectOptions["Size"]}
              color={colorsReverse[intl.locale][selectOptions["Color"]]}
              formatText={CapitalizeFirstLetter}
              quantity={selectedVariantQuantity}
              quantitySelector={setselectedVariantQuantity}
              handleQuantity={handleQuantityChange}
              variant={selectedVariant}
              material_info={
                data.allDirectusLyneupTranslation.nodes[0].product_material_info
              }
              setopenSizeModal={setopenSizeGuideModal}
              selectOptions={selectOptions}
              Errors={Errors}
              returnSizeSelector={returnSizeSelector}
              one_size_system_text={
                data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
              }
              morphology_text={
                data.allDirectusLyneupTranslation.nodes[0].morphology_text
              }
              i_calculate_text={
                data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
              }
              most_suited_text={
                data.allDirectusLyneupTranslation.nodes[0].most_suited_text
              }
              edit_my_information_text={
                data.allDirectusLyneupTranslation.nodes[0]
                  .edit_my_information_text
              }
              return_text={
                data.allDirectusLyneupTranslation.nodes[0].return_text
              }
              menSizeOptions={menSizeParameters}
              menerrorSize={errorSize}
              setmenerrorSize={seterrorSize}
              price={Number(
                selectedVariant
                  ? selectedVariant.price
                  : product.priceRangeV2.maxVariantPrice.amount
              ).toFixed(0)}
              selectedVariantQuantity={setselectedVariantQuantity}
              estimateDeliveryText={
                data.allDirectusCommonTranslation.nodes[0].months_text
              }
              loading={storeContext.adding ? "adding" : ""}
            />
          )}
          <PriceDetails
            details={data.allDirectusLyneupTranslation.nodes[0].alma_popup_text}
            open={openAlmaModal}
            closeHandle={setopenAlmaModal}
          />
          <MobileAddToCartPopup
            size_data={data.allDirectusLyneupTranslation.nodes[0].size}
            color_data={data.allDirectusLyneupTranslation.nodes[0].color}
            open={openMobileAddToCartModal}
            closeHandle={setopenMobileAddToCartModal}
            addToCart={handleAddTocart}
            handleSelectOptions={handleChangeSelectOptions}
            addToCartText={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_add_to_cart_button
            }
            gender={selectOptions["Gender"]}
            size={selectOptions["Size"]}
            color={colorsReverse[intl.locale][selectOptions["Color"]]}
            formatText={CapitalizeFirstLetter}
            quantity={selectedVariantQuantity}
            quantitySelector={setselectedVariantQuantity}
            handleQuantity={handleQuantityChange}
            variant={selectedVariant}
            material_info={
              data.allDirectusLyneupTranslation.nodes[0].product_material_info
            }
            setopenSizeModal={setopenSizeGuideModal}
            selectOptions={selectOptions}
            Errors={Errors}
            returnSizeSelector={returnSizeSelector}
            one_size_system_text={
              data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
            }
            morphology_text={
              data.allDirectusLyneupTranslation.nodes[0].morphology_text
            }
            i_calculate_text={
              data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
            }
            most_suited_text={
              data.allDirectusLyneupTranslation.nodes[0].most_suited_text
            }
            edit_my_information_text={
              data.allDirectusLyneupTranslation.nodes[0]
                .edit_my_information_text
            }
            return_text={data.allDirectusLyneupTranslation.nodes[0].return_text}
            menSizeOptions={menSizeParameters}
            menerrorSize={errorSize}
            setmenerrorSize={seterrorSize}
            loading={loading}
            sizeError={errorSize ? true : false}
            estimateDeliveryText={
              data.allDirectusCommonTranslation.nodes[0].months_text
            }
            price={Number(
              selectedVariant
                ? selectedVariant.price
                : product.priceRangeV2.maxVariantPrice.amount
            ).toFixed(0)}
            selectedVariantQuantity={setselectedVariantQuantity}
            addToCartLoading={storeContext.adding ? "adding" : ""}
          >
            <ProductSizeDetail
              data={
                data.allDirectusLyneupTranslation.nodes[0].product_material_info
              }
              openSizeModal={setopenSizeGuideModal}
              disabled={!(selectOptions && selectOptions["Gender"])}
              mobile
            />
          </MobileAddToCartPopup>

          <ProductInfoBox>
            <ProductImageSection page="lyneup">
              {!(selectOptions["Gender"] && selectOptions["Color"]) && (
                <ProductImageSlider
                  data={LyneUp2BImageSliders[intl.locale]["Default"]}
                  medicaleLogo={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .innovation_section.medical_image
                  }
                  newTag={intl.locale === "fr" ? "Best-seller" : "Bestseller"}
                  page="lyneup"
                  imageSection="couple"
                  video_text={data.allDirectusNewHomePageTranslation.nodes[0]}
                  video="https://www.percko.com/videos/percko.com/gbp-en/lyneup-test/lyne_up_-_video_1_t-shirt_discret.mp4"
                />
              )}

              {selectOptions["Gender"] && selectOptions["Color"] && (
                <ProductImageSlider
                  data={
                    selectOptions["Gender"] && selectOptions["Color"]
                      ? LyneUp2BImageSliders[intl.locale][
                          selectOptions["Gender"]
                        ][selectOptions[["Color"]]]
                      : data.allDirectusLyneupTranslation.nodes[0]
                          .product_images_section.men.noir
                  }
                  medicaleLogo={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .innovation_section.medical_image
                  }
                  page="lyne_home"
                />
              )}
            </ProductImageSection>

            <div className={"only-desktop"} style={{ width: "50%" }}>
              <div className={"only-desktop"}>
                <ProductRightInfoSection page="lyneup">
                  <LyneupBProductTopSection
                    image={
                      data.allDirectusLyneupNewPageTranslation.nodes[0]
                        .lyneup_logo
                    }
                    review_data={data.allDirectusReviewTranslation.nodes}
                    price={
                      discountPrice
                        ? Number(99).toFixed(0)
                        : Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)
                    }
                    title={
                      data.allDirectusLyneup2BPageTranslation.nodes[0]
                        .lyneup_title
                    }
                    intl={intl}
                    openAlma={setopenAlmaModal}
                    isMobile={IsMobile}
                    page="lyneup_landing"
                  />
                  <ProductSummaryTop>
                    {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr" && (
                        <MobileProductBottomPriceInfo>
                          <ProductPriceWrapper align="flex-start">
                            <ProductPrice page="lyneup">
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice>
                            {/* <ProductPlusDetail
                            onClick={() => setopenModal(!openAlmaModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail> */}
                          </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper>
                            <ProductAlmaPrice page="lyneup">
                              ou 3 x{" "}
                              <span>
                                {monthlyPrice(
                                  discountPrice
                                    ? 129
                                    : selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                )}
                              </span>
                            </ProductAlmaPrice>
                            <ProductAlmaDetail
                              onClick={() => setopenAlmaModal(!openAlmaModal)}
                            >
                              {intl.formatMessage({
                                id: "alma_details_text",
                              })}
                            </ProductAlmaDetail>
                          </ProductAlmaPriceWrapper>
                        </MobileProductBottomPriceInfo>
                      )}
                    {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                      <MobileProductBottomPriceInfo>
                        <ProductPriceWrapper align="flex-start">
                          <ProductPrice page="lyneup">
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductPrice>
                          {/* <ProductDiscountPrice page="lyneup">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${discountPrice}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductDiscountPrice>
                        <ProductStrikePrice page="lyneup">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                          <ProductPriceInformationSection />
                        </ProductStrikePrice> */}
                        </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                          <ProductKlarnaSection
                            price={Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                          />
                        </ProductAlmaPriceWrapper>
                      </MobileProductBottomPriceInfo>
                    )}
                    <UserTypeWrapper page="lyneup_2b">
                      {data.allDirectusLyneupTranslation.nodes[0]
                        .choose_gender_text && (
                        <ChooseGenderText
                          dangerouslySetInnerHTML={{
                            __html:
                              data.allDirectusLyneupTranslation.nodes[0]
                                .choose_gender_text,
                          }}
                        />
                      )}
                      <ProductUserType
                        data={
                          data.allDirectusLyneupTranslation.nodes[0].gender.item
                        }
                        handleChange={handleChangeSelectOptions}
                        value={selectOptions["Gender"]}
                        error={selectOptions["Gender"] ? null : Errors.exist}
                        product={"Lyne UP"}
                        page="lyneup"
                      />
                    </UserTypeWrapper>
                    <ProductColorBox
                      data={
                        data.allDirectusLyneupTranslation.nodes[0].color.item
                      }
                      handleChange={handleChangeSelectOptions}
                      disabled={!(selectOptions && selectOptions["Gender"])}
                      gender={selectOptions && selectOptions["Gender"]}
                      value={selectOptions["Color"]}
                      colors={colors}
                      colorsReverse={colorsReverse}
                      formatText={CapitalizeFirstLetter}
                      error={!selectOptions["Color"] && Errors.exist}
                      page="lyneup_2b"
                    />
                    {/*{selectOptions && selectOptions["Gender"] ? (
                    <ProductColorBox
                      data={
                        data.allDirectusLyneupTranslation.nodes[0].color.item
                      }
                      handleChange={handleChangeSelectOptions}
                      disabled={!(selectOptions && selectOptions["Gender"])}
                      gender={selectOptions && selectOptions["Gender"]}
                      value={selectOptions["Color"]}
                      colors={colors}
                      colorsReverse={colorsReverse}
                      formatText={CapitalizeFirstLetter}
                      error={!selectOptions["Color"] && Errors.exist}
                      page="lyneup_2b"
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: "16px",
                        lineHeight: 1.2,
                        fontFamily: "Museo",
                        fontWeight: 400,
                        margin: "20px 0px",
                      }}
                    >
                      {
                        data.allDirectusLyneupTranslation.nodes[0]
                          .product_material_info
                      }
                    </div>
                  )}*/}
                    <ProductSizeDetail
                      data={
                        data.allDirectusLyneupTranslation.nodes[0]
                          .product_material_info
                      }
                      openSizeModal={setopenSizeModal}
                      disabled={!(selectOptions && selectOptions["Gender"])}
                    />
                  </ProductSummaryTop>
                  <ProductSummaryBottom>
                    <div className="lyneup_test product_size_variation_wrapper">
                      {!openMobileAddToCartModal && returnSizeSelector(false)}
                    </div>
                    <div
                      className="size_drop_down"
                      style={{
                        display: errorSize && !IsMobile ? "none" : "block",
                      }}
                    >
                      {!(selectOptions && selectOptions["Gender"]) && (
                        <LayerDisabled />
                      )}
                      <div className="size_drop_down_child">
                        {loading && (
                          <div
                            style={{
                              backgroundColor: "transparent",
                              position: "absolute",
                              left: 0,
                              right: 0,
                              top: 0,
                              bottom: 0,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              paddingTop: 3.5,
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#808080"
                              height={30}
                              width={30}
                              secondaryColor={"#696969"}
                            />
                          </div>
                        )}
                        <Select
                          id="pa_taille_classique"
                          name="Size"
                          onChange={handleChangeSelectOptions}
                          value={selectOptions["Size"]}
                          error={!selectOptions["Size"] && Errors.exist}
                        >
                          <option value={""}>
                            {intl.formatMessage({ id: "my_percko_size_text" })}
                          </option>
                          {data.allDirectusLyneupTranslation.nodes[0].size.item.map(
                            value => (
                              <option value={value} key={`Size-${value}`}>
                                {intl.formatMessage({
                                  id: "my_percko_size_text",
                                })}
                                {" : "}
                                {value}
                              </option>
                            )
                          )}
                        </Select>
                        <MobileSelect
                          onClick={() => setopenMobileAddToCartModal(true)}
                        >
                          {
                            data.allDirectusLyneupTranslation.nodes[0]
                              .my_custom_size_text
                          }
                        </MobileSelect>
                      </div>
                    </div>

                    {Errors &&
                      Errors.exist &&
                      ((process.env.GATSBY_CURRENCY_TYPE != "eur" &&
                        intl.locale != "fr") ||
                        process.env.GATSBY_CURRENCY_TYPE != "gbp") && (
                        <ErrorBox>
                          {Errors.message.map((item, index) => (
                            <span key={index.toString()}>{item}</span>
                          ))}
                        </ErrorBox>
                      )}
                    {((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr") ||
                      process.env.GATSBY_CURRENCY_TYPE === "gbp") &&
                      selectOptions["Gender"] &&
                      selectOptions["Color"] &&
                      data.allDirectusLyneupTranslation.nodes[0]
                        .combo_section && (
                        <LyneComboSection page="lyneup">
                          <LyneComboWrapper>
                            {data.allDirectusLyneupTranslation.nodes[0]
                              .combo_section.single_product_text && (
                              <LyneComboBox
                                onClick={() => handleProductType("Seul")}
                              >
                                {/* {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                                  <LyneComboBoxTagWrapper>
                                    <LyneComboBoxTag lang={intl.locale}>
                                      Sale
                                    </LyneComboBoxTag>
                                  </LyneComboBoxTagWrapper>
                                )} */}
                                <LyneComboBoxTitle
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLyneupTranslation.nodes[0]
                                        .combo_section.single_product_text,
                                  }}
                                />
                                <LyneComboBottomSection>
                                  <LyneComboLeftSection>
                                    {selectOptions["Gender"] == "Female" &&
                                    selectOptions["Color"] == "NUDE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/9ae58bba-984c-4130-a37c-76c97c88d147.png" />
                                    ) : selectOptions["Gender"] == "Female" &&
                                      selectOptions["Color"] == "BLACK" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/08215c54-aeac-4484-a3a0-ddae66d91b54.png" />
                                    ) : selectOptions["Gender"] == "Male" &&
                                      selectOptions["Color"] == "NUDE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/ba048b3c-d06c-42ab-966c-e4e7631acc05.png" />
                                    ) : (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/a85226d0-c0bf-4978-87d9-33a427bff5e2.png" />
                                    )}

                                    <LyneProductRegularPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${Number(
                                        selectedVariant
                                          ? selectedVariant.price
                                          : product.priceRangeV2.maxVariantPrice
                                              .amount
                                      ).toFixed(0)}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductRegularPrice>
                                    {/* <LyneProductDiscountPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneDiscountPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductDiscountPrice>
                                    {process.env.GATSBY_CURRENCY_TYPE ===
                                      "gbp" && (
                                      <LyneProductStrikePrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${Number(
                                          selectedVariant
                                            ? selectedVariant.price
                                            : product.priceRangeV2
                                                .maxVariantPrice.amount
                                        ).toFixed(0)}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductStrikePrice>
                                    )} */}
                                  </LyneComboLeftSection>
                                  <LyneCheckImage
                                    src={
                                      selectProductType === "Seul"
                                        ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                        : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                    }
                                  />
                                </LyneComboBottomSection>
                              </LyneComboBox>
                            )}
                            {data.allDirectusLyneupTranslation.nodes[0]
                              .combo_section.offer_title &&
                              data.allDirectusLyneupTranslation.nodes[0]
                                .combo_section.offer_text && (
                                <LyneComboBox
                                  border="0px"
                                  onClick={() => handleProductType("Pack")}
                                >
                                  <LyneComboBoxTagWrapper>
                                    {/* <LyneComboBoxTag
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data.allDirectusLyneupTranslation
                                          .nodes[0].combo_section.offer_title,
                                    }}
                                    lang={intl.locale}
                                  /> */}
                                    {intl.locale === "fr" ? (
                                      <LyneComboBoxTag lang={intl.locale}>
                                        SOLDES
                                        {/* <span>
                                        Derniers
                                        <br />
                                        jours
                                      </span> */}
                                        {/* <ClockImage src={CoqIcon} /> */}
                                      </LyneComboBoxTag>
                                    ) : (
                                      <LyneComboBoxTag lang={intl.locale}>
                                        Sale
                                      </LyneComboBoxTag>
                                    )}
                                  </LyneComboBoxTagWrapper>
                                  <LyneComboBoxTitle
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data.allDirectusLyneupTranslation
                                          .nodes[0].combo_section.offer_text,
                                    }}
                                  />
                                  <LyneComboBottomSection>
                                    <LyneComboLeftSection>
                                      {selectOptions["Gender"] == "Female" &&
                                      selectOptions["Color"] == "NUDE" ? (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/38bf38a4-e9f6-4b93-971d-2ee896c9dfe2.png" />
                                      ) : selectOptions["Gender"] == "Female" &&
                                        selectOptions["Color"] == "BLACK" ? (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/e272f2a0-7d27-4416-9198-0d14fb0ffbf2.png" />
                                      ) : selectOptions["Gender"] == "Male" &&
                                        selectOptions["Color"] == "NUDE" ? (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/1dc7fd3c-4787-4075-b546-dd68bc113144.png" />
                                      ) : (
                                        <LyneComboBoxImage src="https://static.percko.com/uploads/5ab8d9d8-d87e-46b1-832e-6e56b4e84c2e.png" />
                                      )}
                                      <LyneProductDiscountPrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${lyneGroupDiscountPrice()}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductDiscountPrice>
                                      <LyneProductStrikePrice>
                                        {`${
                                          process.env.GATSBY_CURRENCY_TYPE !==
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }${lyneGroupPrice()}${
                                          process.env.GATSBY_CURRENCY_TYPE ===
                                          "eur"
                                            ? process.env.GATSBY_CURRENCY
                                            : ""
                                        }`}
                                      </LyneProductStrikePrice>
                                    </LyneComboLeftSection>
                                    <LyneCheckImage
                                      src={
                                        selectProductType === "Pack"
                                          ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                          : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                      }
                                    />
                                  </LyneComboBottomSection>
                                </LyneComboBox>
                              )}
                          </LyneComboWrapper>
                          <LyneComboRightBox
                            dangerouslySetInnerHTML={{
                              __html:
                                data.allDirectusLyneupTranslation.nodes[0]
                                  .combo_tooltip,
                            }}
                            lang={intl.locale}
                            page="lyneup"
                          />
                        </LyneComboSection>
                      )}
                    {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr" && (
                        <ProductBottomInfo page="lyneup">
                          {/* <DesktopProductBottomPriceInfo page="lyneup">
                          <ProductPriceWrapper>
                            <ProductPrice page="lyneup">
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice> */}
                          {/* <ProductPlusDetail
                              onClick={() => setopenModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "product_plus_details_text",
                              })}
                            </ProductPlusDetail> */}
                          {/* </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper>
                            <ProductAlmaPrice page="lyneup">
                              ou 3 x{" "}
                              <span>
                                {monthlyPrice(
                                  discountPrice
                                    ? 129
                                    : selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                )}
                              </span>
                            </ProductAlmaPrice>
                            <ProductAlmaDetail
                              onClick={() => setopenAlmaModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "alma_details_text",
                              })}
                            </ProductAlmaDetail>
                          </ProductAlmaPriceWrapper>
                        </DesktopProductBottomPriceInfo> */}
                          <ProductBottomCartButtonBox page="lyneup">
                            {/* <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            alma={true}
                          /> */}
                            <div style={{ width: "100%" }}>
                              <ProductAddToCart
                                id="add_to_cart"
                                loading={storeContext.adding ? "adding" : ""}
                                onClick={
                                  selectOptions["Gender"]
                                    ? handleAddTocart
                                    : () => {}
                                }
                                disabled={
                                  selectOptions["Gender"] ? false : true
                                }
                              >
                                {
                                  data.allDirectusLyneupTranslation.nodes[0]
                                    .product_add_to_cart_button
                                }
                              </ProductAddToCart>
                              {selectOptions["Size"] && (
                                <ProductEstimateShippingBox>
                                  <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                  <ProductEstimateShippingText>
                                    {cartEstimateDeliveryText(
                                      "LyneUp",
                                      data.allDirectusCommonTranslation.nodes[0]
                                        .months_text,
                                      intl.locale,
                                      selectOptions["Size"],
                                      selectOptions["Gender"],
                                      selectOptions["Color"]
                                    )}
                                  </ProductEstimateShippingText>
                                </ProductEstimateShippingBox>
                              )}
                            </div>
                          </ProductBottomCartButtonBox>
                        </ProductBottomInfo>
                      )}
                    {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                      <ProductBottomInfo>
                        {/* <DesktopProductBottomPriceInfo page="lyneup">
                        <ProductPriceWrapper>
                          <ProductPrice page="lyneup">
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductPrice> */}
                        {/* <ProductDiscountPrice page="lyneup">
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${discountPrice}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductDiscountPrice>
                          <ProductStrikePrice page="lyneup">
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                            <ProductPriceInformationSection />
                          </ProductStrikePrice> */}
                        {/* </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                          <ProductKlarnaSection
                            price={Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                          />
                        </ProductAlmaPriceWrapper>
                      </DesktopProductBottomPriceInfo> */}
                        <ProductBottomCartButtonBox>
                          {/* <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          alma={true}
                        /> */}
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLyneupTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon
                                  className="lazyload"
                                  data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                                />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneUp",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductBottomCartButtonBox>
                      </ProductBottomInfo>
                    )}
                    {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                      process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                      (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                        intl.locale != "fr")) && (
                      <ProductInfoBottom page="lyneup">
                        <DesktopPrice>
                          <ProductPriceWrapper>
                            <ProductPrice page="lyneup">
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice>
                            {/* <ProductPlusDetail
                            onClick={() => setopenModal(!openModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail> */}
                          </ProductPriceWrapper>
                        </DesktopPrice>
                        <ProductInfoBottomRight>
                          <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                          />
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLyneupTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneUp",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductInfoBottomRight>
                      </ProductInfoBottom>
                    )}
                  </ProductSummaryBottom>

                  {Errors &&
                    Errors.exist &&
                    ((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr") ||
                      process.env.GATSBY_CURRENCY_TYPE === "gbp") && (
                      <ErrorBox>
                        {Errors.message.map((item, index) => (
                          <span key={index.toString()}>{item}</span>
                        ))}
                      </ErrorBox>
                    )}
                </ProductRightInfoSection>
              </div>
            </div>
            <div className={"only-mobile row"}>
              <div>
                <LyneupBProductTopSection
                  image={
                    data.allDirectusLyneupNewPageTranslation.nodes[0]
                      .lyneup_logo
                  }
                  review_data={data.allDirectusReviewTranslation.nodes}
                  price={
                    discountPrice
                      ? Number(99).toFixed(0)
                      : Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)
                  }
                  title={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .lyneup_title
                  }
                  intl={intl}
                  openAlma={setopenAlmaModal}
                  page="lyneup_landing"
                />
              </div>
              <div>
                <div
                  style={{
                    background: "#e8e8e8",
                    padding: "15px 0px 25px",
                    width: "100%",
                  }}
                >
                  {/* {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                  intl.locale === "fr" && (
                    <DesktopProductBottomPriceInfo
                      style={{ display: "flex", padding: "0px 20px 10px" }}
                    >
                      <ProductPriceWrapper style={{ alignItems: "flex-start" }}>
                        <ProductPrice page="lyneup">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductPrice>
                        <ProductPlusDetail
                          onClick={() => setopenModal(!openModal)}
                        >
                          {intl.formatMessage({
                            id: "product_plus_details_text",
                          })}
                        </ProductPlusDetail>
                      </ProductPriceWrapper>
                      <ProductAlmaPriceWrapper>
                        <ProductAlmaPrice page="lyneup">
                          ou 3 x{" "}
                          <span>
                            {monthlyPrice(
                              discountPrice
                                ? 129
                                : selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            )}
                          </span>
                        </ProductAlmaPrice>
                        <ProductAlmaDetail
                          onClick={() => setopenAlmaModal(!openModal)}
                        >
                          {intl.formatMessage({
                            id: "alma_details_text",
                          })}
                        </ProductAlmaDetail>
                      </ProductAlmaPriceWrapper>
                    </DesktopProductBottomPriceInfo>
                  )} */}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <DesktopProductBottomPriceInfo
                      style={{ display: "flex", padding: "0px 20px 0px" }}
                    >
                      <ProductPriceWrapper style={{ alignItems: "flex-start" }}>
                        {/* <ProductPrice page="lyneup">
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                        <ProductPriceInformationSection />
                      </ProductPrice> */}
                        {/* <ProductDiscountPrice page="lyneup">
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${discountPrice}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                      </ProductDiscountPrice>
                      <ProductStrikePrice page="lyneup">
                        {`${
                          process.env.GATSBY_CURRENCY_TYPE !== "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }${Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}${
                          process.env.GATSBY_CURRENCY_TYPE === "eur"
                            ? process.env.GATSBY_CURRENCY
                            : ""
                        }`}
                        <ProductPriceInformationSection />
                      </ProductStrikePrice> */}
                      </ProductPriceWrapper>
                      {/* <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                      <ProductKlarnaSection
                        price={Number(
                          selectedVariant
                            ? selectedVariant.price
                            : product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}
                      />
                    </ProductAlmaPriceWrapper> */}
                    </DesktopProductBottomPriceInfo>
                  )}
                  {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                    process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                    (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale != "fr")) && (
                    <DesktopPrice
                      style={{ display: "flex", padding: "0px 20px 10px" }}
                    >
                      <ProductPriceWrapper>
                        <ProductPrice page="lyneup">
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductPrice>
                        <ProductPlusDetail
                          onClick={() => setopenModal(!openModal)}
                        >
                          {intl.formatMessage({
                            id: "product_plus_details_text",
                          })}
                        </ProductPlusDetail>
                      </ProductPriceWrapper>
                    </DesktopPrice>
                  )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <ProductKlarnaSection
                      price={Number(
                        selectedVariant
                          ? selectedVariant.price
                          : product.priceRangeV2.maxVariantPrice.amount
                      ).toFixed(0)}
                    />
                  )}
                  <Select
                    style={{ display: "none" }}
                    id="pa_taille_classique"
                    name="Size"
                    onChange={handleChangeSelectOptions}
                    value={selectOptions["Size"]}
                    error={!selectOptions["Size"] && Errors.exist}
                  >
                    <option value={""}>
                      {intl.formatMessage({ id: "my_percko_size_text" })}
                    </option>
                    {data.allDirectusLyneupTranslation.nodes[0].size.item.map(
                      value => (
                        <option value={value} key={`Size-${value}`}>
                          {intl.formatMessage({
                            id: "my_percko_size_text",
                          })}
                          {" : "}
                          {value}
                        </option>
                      )
                    )}
                  </Select>
                  <GenderSection
                    style={{
                      flexDirection:
                        selectOptions["Gender"] === "Female"
                          ? "column-reverse"
                          : "column",
                    }}
                  >
                    {data.allDirectusLyneupNewPageTranslation.nodes[0].gender_section.list.map(
                      (item, index) => {
                        return (
                          <GenderSelector
                            key={index.toString()}
                            onClick={() => handleGender(item.value)}
                            active={
                              selectOptions["Gender"] ===
                              genders[intl.locale][item.value]
                            }
                            error={
                              selectOptions["Gender"] ? null : Errors.exist
                            }
                          >
                            <GenderSelectorBox>
                              <GenderSelectorImage src={item.image} />
                              <GenderSelectorText
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              />
                            </GenderSelectorBox>
                            <GenderArrow>
                              <FaArrowRight />
                            </GenderArrow>
                          </GenderSelector>
                        )
                      }
                    )}
                  </GenderSection>

                  <ColorSection>
                    {data.allDirectusLyneupNewPageTranslation.nodes[0].color_section.list.map(
                      (item, index) => {
                        return (
                          <ColorSelector
                            key={index.toString()}
                            onClick={() => handleColor(item.value)}
                            active={
                              selectOptions["Color"] ===
                              colors[intl.locale][item.value]
                            }
                          >
                            <ColorSelectorBox>
                              <ColorSelectorImage
                                src={
                                  selectOptions["Gender"] === "Male"
                                    ? item.men_image
                                    : item.women_image
                                }
                              />
                              <ColorSelectorText
                                dangerouslySetInnerHTML={{
                                  __html: item.text,
                                }}
                              />
                            </ColorSelectorBox>
                          </ColorSelector>
                        )
                      }
                    )}
                  </ColorSection>

                  <SizeSelectorTitle
                    style={{
                      textAlign:
                        process.env.GATSBY_CURRENCY_TYPE === "gbp"
                          ? "center"
                          : "left",
                    }}
                  >
                    {
                      data.allDirectusLyneupNewPageTranslation.nodes[0]
                        .size_selector_title
                    }
                  </SizeSelectorTitle>

                  <SizeSection>
                    <SizeSelector
                      onClick={() => sizeSelectorFunc("calculate")}
                      active={
                        sizeSelector === "calculate" &&
                        !selectOptions["Size"] &&
                        true
                      }
                    >
                      <SizeSelectorText>
                        {
                          data.allDirectusLyneupNewPageTranslation.nodes[0]
                            .calculate_size_text
                        }
                      </SizeSelectorText>
                    </SizeSelector>
                    <SizeSelectorMiddleText>
                      {intl.locale === "fr" ? "Ou" : "Or"}
                    </SizeSelectorMiddleText>
                    <SizeSelector
                      onClick={() => sizeSelectorFunc("choose")}
                      active={
                        selectOptions["Size"]
                          ? "size"
                          : sizeSelector === "choose"
                          ? true
                          : false
                      }
                    >
                      <SizeSelectorText>
                        {selectOptions["Size"]
                          ? `${
                              intl.locale === "fr"
                                ? "Votre taille :"
                                : "Your size:"
                            } ${selectOptions["Size"]}`
                          : data.allDirectusLyneupNewPageTranslation.nodes[0]
                              .choose_size_text}
                      </SizeSelectorText>
                    </SizeSelector>
                  </SizeSection>

                  {Errors &&
                    Errors.exist &&
                    ((process.env.GATSBY_CURRENCY_TYPE != "eur" &&
                      intl.locale != "fr") ||
                      process.env.GATSBY_CURRENCY_TYPE != "gbp") && (
                      <LyneupErrorBox>
                        {Errors.message.map((item, index) => (
                          <span key={index.toString()}>{item}</span>
                        ))}
                      </LyneupErrorBox>
                    )}
                  {((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr") ||
                    process.env.GATSBY_CURRENCY_TYPE === "gbp") &&
                    selectOptions["Gender"] &&
                    selectOptions["Color"] &&
                    data.allDirectusLyneupTranslation.nodes[0]
                      .combo_section && (
                      <LyneComboSection page="lyneup">
                        <LyneComboWrapper>
                          {data.allDirectusLyneupTranslation.nodes[0]
                            .combo_section.single_product_text && (
                            <LyneComboBox
                              onClick={() => handleProductType("Seul")}
                            >
                              {/* {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                              <LyneComboBoxTagWrapper>
                                <LyneComboBoxTag
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLyneupTranslation.nodes[0]
                                        .combo_section.offer_title,
                                  }}
                                  lang={intl.locale}
                                />
                              </LyneComboBoxTagWrapper>
                            )} */}
                              <LyneComboBoxTitle
                                dangerouslySetInnerHTML={{
                                  __html:
                                    data.allDirectusLyneupTranslation.nodes[0]
                                      .combo_section.single_product_text,
                                }}
                              />
                              <LyneComboBottomSection>
                                <LyneComboLeftSection>
                                  {selectOptions["Gender"] == "Female" &&
                                  selectOptions["Color"] == "NUDE" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/9ae58bba-984c-4130-a37c-76c97c88d147.png" />
                                  ) : selectOptions["Gender"] == "Female" &&
                                    selectOptions["Color"] == "BLACK" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/08215c54-aeac-4484-a3a0-ddae66d91b54.png" />
                                  ) : selectOptions["Gender"] == "Male" &&
                                    selectOptions["Color"] == "NUDE" ? (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/ba048b3c-d06c-42ab-966c-e4e7631acc05.png" />
                                  ) : (
                                    <LyneComboBoxImage src="https://static.percko.com/uploads/a85226d0-c0bf-4978-87d9-33a427bff5e2.png" />
                                  )}
                                  <LyneProductDiscountPrice>
                                    {`${
                                      process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }${lyneDiscountPrice()}${
                                      process.env.GATSBY_CURRENCY_TYPE === "eur"
                                        ? process.env.GATSBY_CURRENCY
                                        : ""
                                    }`}
                                  </LyneProductDiscountPrice>
                                  {process.env.GATSBY_CURRENCY_TYPE ===
                                    "gbp" && (
                                    <LyneProductStrikePrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${Number(
                                        selectedVariant
                                          ? selectedVariant.price
                                          : product.priceRangeV2.maxVariantPrice
                                              .amount
                                      ).toFixed(0)}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductStrikePrice>
                                  )}
                                </LyneComboLeftSection>
                                <LyneCheckImage
                                  src={
                                    selectProductType === "Seul"
                                      ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                      : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                  }
                                />
                              </LyneComboBottomSection>
                            </LyneComboBox>
                          )}
                          {data.allDirectusLyneupTranslation.nodes[0]
                            .combo_section.offer_title &&
                            data.allDirectusLyneupTranslation.nodes[0]
                              .combo_section.offer_text && (
                              <LyneComboBox
                                border="0px"
                                onClick={() => handleProductType("Pack")}
                              >
                                <LyneComboBoxTagWrapper>
                                  {/* <LyneComboBoxTag
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLyneupTranslation.nodes[0]
                                        .combo_section.offer_title,
                                  }}
                                  lang={intl.locale}
                                /> */}
                                  {intl.locale === "fr" ? (
                                    <LyneComboBoxTag lang={intl.locale}>
                                      SOLDES
                                      {/* <span>
                                      Derniers
                                      <br />
                                      jours
                                    </span> */}
                                      {/* <ClockImage src={CoqIcon} /> */}
                                    </LyneComboBoxTag>
                                  ) : (
                                    <LyneComboBoxTag lang={intl.locale}>
                                      Sale
                                    </LyneComboBoxTag>
                                  )}
                                </LyneComboBoxTagWrapper>
                                <LyneComboBoxTitle
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.allDirectusLyneupTranslation.nodes[0]
                                        .combo_section.offer_text,
                                  }}
                                />
                                <LyneComboBottomSection>
                                  <LyneComboLeftSection>
                                    {selectOptions["Gender"] == "Female" &&
                                    selectOptions["Color"] == "NUDE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/38bf38a4-e9f6-4b93-971d-2ee896c9dfe2.png" />
                                    ) : selectOptions["Gender"] == "Female" &&
                                      selectOptions["Color"] == "BLACK" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/e272f2a0-7d27-4416-9198-0d14fb0ffbf2.png" />
                                    ) : selectOptions["Gender"] == "Male" &&
                                      selectOptions["Color"] == "NUDE" ? (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/1dc7fd3c-4787-4075-b546-dd68bc113144.png" />
                                    ) : (
                                      <LyneComboBoxImage src="https://static.percko.com/uploads/5ab8d9d8-d87e-46b1-832e-6e56b4e84c2e.png" />
                                    )}
                                    <LyneProductDiscountPrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneGroupDiscountPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductDiscountPrice>
                                    <LyneProductStrikePrice>
                                      {`${
                                        process.env.GATSBY_CURRENCY_TYPE !==
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }${lyneGroupPrice()}${
                                        process.env.GATSBY_CURRENCY_TYPE ===
                                        "eur"
                                          ? process.env.GATSBY_CURRENCY
                                          : ""
                                      }`}
                                    </LyneProductStrikePrice>
                                  </LyneComboLeftSection>
                                  <LyneCheckImage
                                    src={
                                      selectProductType === "Pack"
                                        ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                                        : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                                    }
                                  />
                                </LyneComboBottomSection>
                              </LyneComboBox>
                            )}
                        </LyneComboWrapper>
                        <LyneComboRightBox
                          dangerouslySetInnerHTML={{
                            __html:
                              data.allDirectusLyneupTranslation.nodes[0]
                                .combo_tooltip,
                          }}
                          lang={intl.locale}
                          page="lyneup"
                        />
                      </LyneComboSection>
                    )}

                  {selectOptions["Gender"] && (
                    <AddToCartButton
                      loading={storeContext.adding ? "adding" : ""}
                      onClick={
                        selectOptions["Gender"] ? handleAddTocart : () => {}
                      }
                      disabled={selectOptions["Size"] ? false : true}
                    >
                      {
                        data.allDirectusLyneupNewPageTranslation.nodes[0]
                          .add_to_cart_button_text
                      }
                    </AddToCartButton>
                  )}

                  {Errors &&
                    Errors.exist &&
                    ((process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale === "fr") ||
                      process.env.GATSBY_CURRENCY_TYPE === "gbp") && (
                      <LyneupErrorBox>
                        {Errors.message.map((item, index) => (
                          <span key={index.toString()}>{item}</span>
                        ))}
                      </LyneupErrorBox>
                    )}

                  {selectOptions["Size"] && (
                    <ProductEstimateShippingBox page="lyneup_mobile">
                      <ProductEstimateShippingIcon
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                      />
                      <ProductEstimateShippingText>
                        {cartEstimateDeliveryText(
                          "LyneUp",
                          data.allDirectusCommonTranslation.nodes[0]
                            .months_text,
                          intl.locale,
                          selectOptions["Size"],
                          selectOptions["Gender"],
                          selectOptions["Color"]
                        )}
                      </ProductEstimateShippingText>
                    </ProductEstimateShippingBox>
                  )}
                </div>
              </div>
            </div>
          </ProductInfoBox>
        </LyneupContainer>

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusCommonTranslation.nodes[0]
            .new_testimonial_section && (
            <TestimonialSection
              data={
                data.allDirectusCommonTranslation.nodes[0]
                  .new_testimonial_section
              }
            />
          )}
        {/* {data.allDirectusLyneup2BPageTranslation.nodes[0].user_section && (
          <LyneupUserSection>
            <LyneUpTextContainer>
              <LyneupUserTextBox style={{ maxWidth: "700px" }}>
                <LyneupSectionTitle
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusLyneup2BPageTranslation.nodes[0]
                        .user_section.title,
                  }}
                />
                <LyneupSectionText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusLyneup2BPageTranslation.nodes[0]
                        .user_section.text,
                  }}
                />
                <LyneupSectionText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusLyneup2BPageTranslation.nodes[0]
                        .user_section.name,
                  }}
                />
              </LyneupUserTextBox>
            </LyneUpTextContainer>
          </LyneupUserSection>
        )} */}
        {IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section && (
            <LyneupUserSection>
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0].back_section
                    .mobile_image
                }
              />
              <LyneUpTextContainer>
                <LyneupUserTextBox>
                  {/* <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.title,
                    }}
                  /> */}
                  <LyneupSectionTitle
                    style={{
                      fontSize: "20px",
                      fontFamily: "Gotham Book",
                      fontWeight: "normal",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  />
                  {/* <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  /> */}
                </LyneupUserTextBox>
              </LyneUpTextContainer>
            </LyneupUserSection>
          )}
        {IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section
            .list && (
            <LyneupBenefitsSection>
              {data.allDirectusLyneup2BPageTranslation.nodes[0].back_section.list.map(
                (item, index) => {
                  return (
                    <LyneupBenefitsBox key={index.toString()}>
                      {item.image && (
                        <LyneupBenefitsImage
                          className="lazyload"
                          data-src={item.image}
                        />
                      )}
                      {item.title && (
                        <LyneupBenefitsText
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        />
                      )}
                    </LyneupBenefitsBox>
                  )
                }
              )}
            </LyneupBenefitsSection>
          )}
        {!IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].back_section && (
            <LyneupDesktopSection>
              <LyneupDesktopTextSection>
                <LyneupUserTextBox>
                  {/* <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.title,
                    }}
                  /> */}
                  <LyneupUserLogoImage
                    className="lazyload"
                    data-src="https://static.percko.com/uploads/21f9ad30-2a66-47b1-be80-548095d8b34d.svg"
                  />
                  <LyneupSectionTitle
                    style={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontFamily: "Gotham Book",
                      fontWeight: "normal",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  />
                  {/* <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .back_section.text,
                    }}
                  /> */}
                  {data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .back_section &&
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .back_section.list && (
                      <LyneupBenefitsSection>
                        {data.allDirectusLyneup2BPageTranslation.nodes[0].back_section.list.map(
                          (item, index) => {
                            return (
                              <LyneupBenefitsBox key={index.toString()}>
                                {item.image && (
                                  <LyneupBenefitsImage
                                    className="lazyload"
                                    data-src={item.image}
                                  />
                                )}
                                {item.title && (
                                  <LyneupBenefitsText
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  />
                                )}
                              </LyneupBenefitsBox>
                            )
                          }
                        )}
                      </LyneupBenefitsSection>
                    )}
                </LyneupUserTextBox>
              </LyneupDesktopTextSection>
              <LyneupDesktopImageSection top="100px">
                <LyneupDesktopImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .back_section.desktop_image
                  }
                />
              </LyneupDesktopImageSection>
            </LyneupDesktopSection>
          )}
        {!IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].spine_section &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].circle_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupSpineCircleContainer>
                {/* {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .spine_section && (
                  <LyneupDesktopSpineCircleSection>
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .spine_section.title,
                      }}
                    />
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .spine_section.desktop_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .spine_section.desktop_image
                        }
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )} */}
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .circle_section && (
                  <LyneupDesktopSpineCircleSection>
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .circle_section.title,
                      }}
                      style={{ marginBottom: "30px" }}
                    />
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .circle_section.desktop_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .circle_section.desktop_image
                        }
                        style={{ maxWidth: "900px" }}
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}
        {IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].circle_section && (
            <LyneupCircleSection>
              <LyneupSectionTitle
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .circle_section.title,
                }}
                style={{ marginBottom: "30px" }}
              />
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .circle_section.mobile_image
                }
              />
            </LyneupCircleSection>
          )}
        {IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].action_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupSpineCircleContainer>
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .action_section && (
                  <LyneupDesktopSpineCircleSection
                    style={{ alignItems: "flex-start" }}
                  >
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.title,
                      }}
                    />
                    {/* <LyneupSectionText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.text,
                      }}
                      style={{ maxWidth: "100%", margin: "10px 0px 10px" }}
                    /> */}
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .action_section.mobile_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.mobile_image
                        }
                        style={{
                          maxWidth: "1080px",
                          width: "calc(100% + 40px)",
                          marginLeft: "-20px",
                        }}
                      />
                    )}
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .landing_button_text && (
                      <LyneUpLandingTopButton
                        className="sticy-lyneup-button"
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneup2BPageTranslation.nodes[0]
                              .landing_button_text,
                        }}
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}
        {!IsMobile &&
          process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].action_section && (
            <LyneupDesktopSection style={{ background: "#A3C5DF" }}>
              <LyneupSpineCircleContainer>
                {data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .action_section && (
                  <LyneupDesktopSpineCircleSection>
                    <LyneupSectionTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.title,
                      }}
                    />
                    <LyneupSectionText
                      dangerouslySetInnerHTML={{
                        __html:
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.text,
                      }}
                      style={{
                        maxWidth: "100%",
                        margin: "0px 0px 20px",
                        fontSize: "18px",
                      }}
                    />
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .action_section.desktop_image && (
                      <LyneupSpineCircleImage
                        className="lazyload"
                        data-src={
                          data.allDirectusLyneup2BPageTranslation.nodes[0]
                            .action_section.desktop_image
                        }
                        style={{ maxWidth: "900px" }}
                      />
                    )}
                    {data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .landing_button_text && (
                      <LyneUpLandingTopButton
                        className="sticy-lyneup-button"
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneup2BPageTranslation.nodes[0]
                              .landing_button_text,
                        }}
                      />
                    )}
                  </LyneupDesktopSpineCircleSection>
                )}
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusNewHomePageTranslation.nodes[0].result_section && (
            <ResultHomeSection
              data={
                data.allDirectusNewHomePageTranslation.nodes[0].result_section
              }
              page="lyneup"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          (intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusReviewTranslation.nodes && (
            <TrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              id={"review_section"}
            />
          )}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusNewHomePageTranslation.nodes[0]
            .testimonial_section && (
            <TestimonialHomeSection
              data={
                data.allDirectusNewHomePageTranslation.nodes[0]
                  .testimonial_section
              }
              page="lyneup"
              buttonText={
                data.allDirectusLyneup2BPageTranslation.nodes[0]
                  .landing_button_text
              }
            />
          )}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" && (
          <LyneHomeCrossProductSection
            data={
              data.allDirectusLyneupNewPageTranslation.nodes[0]
                .product_cross_section
            }
            pageName="lyne_home"
          />
        )}

        {process.env.GATSBY_ACTIVE_ENV != "gbp" && (
          <LyneHomeInnovationSection
            data={
              data.allDirectusLyneup2BPageTranslation.nodes[0]
                .innovation_section
            }
          />
        )}

        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          !IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupDesktopSection style={{ background: "#fff" }}>
              <LyneupDesktopImageSection topRight="100px">
                <LyneupDesktopImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.desktop_image
                  }
                />
              </LyneupDesktopImageSection>
              <LyneupDesktopTextSection>
                <LyneupUserTextBox>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.title,
                    }}
                  />
                  <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.text,
                    }}
                  />
                  <EnterPriseSectionRedirect
                    to={`${
                      intl.locale === "fr"
                        ? "/dispositif-medical/#entreprise"
                        : "/scientific-study/#entreprise"
                    }`}
                  >
                    <LyneupDesktopTextImage
                      className="lazyload"
                      data-src={
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.logo_image
                      }
                    />
                  </EnterPriseSectionRedirect>
                </LyneupUserTextBox>
              </LyneupDesktopTextSection>
            </LyneupDesktopSection>
          )}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupExpertSection>
              <LyneUpSectionBgImage
                className="lazyload"
                data-src={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .expert_section.mobile_image
                }
              />
              <LyneUpTextContainer>
                <LyneupUserTextBox>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.title,
                    }}
                  />
                  <LyneupSectionText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .expert_section.text,
                    }}
                  />
                </LyneupUserTextBox>
              </LyneUpTextContainer>
            </LyneupExpertSection>
          )}
        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].expert_section && (
            <LyneupExpertSection
              style={{ background: "#f0f0f0", padding: "5px 15px 10px 0px" }}
            >
              <EnterPriseSectionRedirect
                to={`${
                  intl.locale === "fr"
                    ? "/dispositif-medical/#entreprise"
                    : "/scientific-study/#entreprise"
                }`}
                style={{ margin: "0px" }}
              >
                <LyneupDesktopTextImage
                  className="lazyload"
                  data-src={
                    data.allDirectusLyneup2BPageTranslation.nodes[0]
                      .expert_section.logo_image
                  }
                />
              </EnterPriseSectionRedirect>
            </LyneupExpertSection>
          )}
        {/* {!IsMobile &&
          data.allDirectusLyneup2BPageTranslation.nodes[0].zoom_section && (
            <LyneupDesktopSection style={{ background: "#A3C5DF" }}>
              <LyneupSpineCircleContainer>
                <LyneupDesktopSpineCircleSection>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneup2BPageTranslation.nodes[0]
                          .zoom_section.title,
                    }}
                  />
                  {intl.locale === "fr" ? (
                    <LyneupSpineCircleImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/90bda155-15ee-409b-9083-e5da04c4c02d.png"
                      style={{ maxWidth: "1200px" }}
                    />
                  ) : (
                    <LyneupSpineCircleImage
                      className="lazyload"
                      data-src="https://static.percko.com/uploads/ab87d030-dfe8-4ee7-b721-c4bd6aadd799.png"
                      style={{ maxWidth: "1200px" }}
                    />
                  )}
                </LyneupDesktopSpineCircleSection>
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )}
        {/* {!IsMobile && (
          <LyneupUserSection>
            <LyneUpSectionBgImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/dc92df19-258c-46fa-86a4-736850e8f9b7.jpg"
            />
            <LyneUpTextContainer>
              <LyneupUserTextBox
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LyneupSectionTitle
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusLyneupNewPageTranslation.nodes[0]
                        .video_section.title,
                  }}
                  style={{
                    margin: "0px",
                  }}
                />
              </LyneupUserTextBox>
            </LyneUpTextContainer>
          </LyneupUserSection>
        )}
        {!IsMobile &&
          data.allDirectusTvAdsSectionTranslation.nodes.length > 0 &&
          data.allDirectusLyneupNewPageTranslation.nodes[0].logo_section && (
            <LyneupDesktopSection
              style={{
                background: "#f5f5f5",
                padding: "0px 0px 50px 0px",
              }}
            >
              <LyneupSpineCircleContainer>
                <LyneupDesktopSpineCircleSection>
                  <LyneupSectionTitle
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneupNewPageTranslation.nodes[0]
                          .logo_section.title,
                    }}
                  />
                  {data.allDirectusTvAdsSectionTranslation.nodes[0].slider &&
                    data.allDirectusTvAdsSectionTranslation.nodes[0].slider
                      .list && (
                      <TvAdsSlider
                        data={
                          data.allDirectusTvAdsSectionTranslation.nodes[0]
                            .slider.list
                        }
                        sliderLinkText={
                          data.allDirectusTvAdsSectionTranslation.nodes[0]
                            .slider_link_text
                        }
                        page="lyneup_2b"
                      />
                    )}
                </LyneupDesktopSpineCircleSection>
              </LyneupSpineCircleContainer>
            </LyneupDesktopSection>
          )} */}

        {process.env.GATSBY_ACTIVE_ENV != "gbp" &&
          data.allDirectusTvAdsSectionTranslation.nodes.length > 0 && (
            <TvAdsSection
              data={data.allDirectusTvAdsSectionTranslation.nodes[0]}
              page="home"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0].page_section_list && (
            <LyneupPageSectionList
              data={
                data.allDirectusLyneupTranslation.nodes[0].page_section_list
              }
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0].highlight_section && (
            <LyneupHighlightSection
              data={
                data.allDirectusLyneupTranslation.nodes[0].highlight_section
              }
              id="highlights"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0]
            .product_information_section && (
            <LyneupProductInformationSection
              data={
                data.allDirectusLyneupTranslation.nodes[0]
                  .product_information_section
              }
              id="product_information"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0].technology_section && (
            <LyneupTechnologySection
              data={
                data.allDirectusLyneupTranslation.nodes[0].technology_section
              }
              id="technology"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0].medical_section && (
            <LyneupMedicalSection
              data={data.allDirectusLyneupTranslation.nodes[0].medical_section}
              id="medical_device"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0]
            .how_to_use_lyneup_section && (
            <LyneupUseSection
              data={
                data.allDirectusLyneupTranslation.nodes[0]
                  .how_to_use_lyneup_section
              }
              id="how_to_use_lyneup"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusLyneupTranslation.nodes[0].review_section && (
            <LyneupReviewSection
              data={data.allDirectusLyneupTranslation.nodes[0].review_section}
              id="review"
            />
          )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
          data.allDirectusReviewTranslation.nodes && (
            <TrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              id={"review_section"}
              page="lyneup-test"
            />
          )}

        {process.env &&
        data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section
            }
            pageName="lyneup_gbp"
            id="faq"
          />
        ) : (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].product_faq_section
            }
            pageName="lyneup"
          />
        )}

        {process.env.GATSBY_ACTIVE_ENV === "gbp" && (
          <LyneHomeCrossProductSection
            data={
              data.allDirectusLyneupNewPageTranslation.nodes[0]
                .product_cross_section
            }
            id="other_products"
            page="lyneup"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_bestseller_text
        product_images_section {
          default_image
          men {
            noir
            nude
          }
          women {
            noir
            nude
          }
        }
        product_material_info
        product_add_to_cart_button
        product_blue_banner_text
        product_result_section {
          section_title
          list {
            title
            mobile_title
            text
          }
        }
        product_why_choose_section {
          section_title
          mobile_section_title
          slider {
            image
            title
            text
          }
          text_list {
            image
            text
            mobile_text
          }
        }
        product_expert_section {
          section_title
          mobile_section_title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        product_satisfied_client_section {
          title
          mobile_title
          subtitle
          left_text {
            title
            text
          }
          right_text {
            title
            text
            link
            link_url
          }
        }
        product_video_testimonial {
          title
          subtitle
          testimonials {
            title
            author
            thumb
            video
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        product_companies_logo_slider {
          title
          subtitle
          list
        }
        size {
          item
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        male_size_chart {
          data {
            url
          }
        }
        female_size_chart {
          data {
            url
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        price_details
        size_popup_text
        language
        my_custom_size_text
        one_size_system_text
        morphology_text
        i_calculate_text
        most_suited_text
        edit_my_information_text
        return_text
        alma_popup_text
        testimonial_section {
          list {
            image
            text
            mobile_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        highlight_section {
          image
          title
          text
        }
        product_information_section {
          title
          list {
            image
            text
          }
        }
        technology_section {
          title
          text
          image
          mobile_image
          button_text
          button_url
        }
        medical_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        how_to_use_lyneup_section {
          title
          image
          mobile_image
          button_text
        }
        review_section {
          title
          text
          review_list {
            image
            name
            designation
            company
            city
            text
          }
          media_title
          media_list {
            image
            text
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        color_section {
          list {
            women_image
            men_image
            text
            value
          }
        }
        combo_section {
          single_product_text
          offer_title
          offer_text
        }
        combo_tooltip
      }
    }
    allDirectusLyneupNewPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_title
        lyneup_logo
        technology_section {
          title
          image
          list {
            image
            title
          }
        }
        testimonials_section {
          list {
            image
            text
          }
        }
        sedentary_section {
          title
          text
          image
        }
        action_section {
          title
          text
          list {
            text
            image
          }
        }
        virtous_circle_section {
          title
          image
        }
        benefits_section {
          title
          top_image
          bottom_image
          text
        }
        recommend_section {
          title
          list {
            image
            text
          }
        }
        experts_section {
          title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        medical_certificate_section {
          text
          image
        }
        zoom_section {
          title
          list {
            text
            image
          }
        }
        video_section {
          image
          title
        }
        logo_section {
          title
          list {
            image
            text
          }
        }
        lyneup_satisfied_section {
          title
          left_text
          right_text
        }
        lyneup_faq_title
        enterprise_section {
          title
          list {
            image
            text
            link_text
            link_url
          }
        }
        other_products {
          title
          list {
            image
            title
            logo
            subtitle
            text
            button_text
            button_url
          }
        }
        product_cross_section {
          title
          list {
            image
            title
            logo
            button_text
            button_url
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        color_section {
          list {
            women_image
            men_image
            text
            value
          }
        }
        size_selector_title
        calculate_size_text
        choose_size_text
        add_to_cart_button_text
        male_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text {
          men_title
          women_title
          subtitle
          men_image
          women_image
          left_text
          right_text
        }
      }
    }
    allDirectusLyneup2BPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_title
        lyneup_logo
        testimonial_section {
          list {
            image
            text
          }
        }
        user_section {
          desktop_image
          mobile_image
          title
          text
          name
        }
        back_section {
          desktop_image
          mobile_image
          title
          text
          list {
            image
            title
          }
        }
        expert_section {
          desktop_image
          mobile_image
          title
          text
          logo_image
        }
        result_section {
          title
          text
          list {
            image
            title
          }
        }
        innovation_section {
          flag_image
          medical_image
          desktop_image
          mobile_image
          title
          text
        }
        action_section {
          title
          text
          desktop_image
          mobile_image
          button_text
          button_url
        }
        spine_section {
          title
          desktop_image
          mobile_image
        }
        circle_section {
          title
          desktop_image
          mobile_image
        }
        zoom_section {
          title
          image
          text
          list {
            image
            text
          }
        }
        landing_button_text
      }
    }
    allDirectusReviewTranslation(
      filter: { review: { product: { eq: "lyneup" } }, language: { eq: $lang } }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusNewHomePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        top_banner_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        top_banner_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        top_banner_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        top_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        lyneup_section_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        lyneup_section_tablet_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
        lyneup_section_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        lyneup_banner_text {
          logo_image
          top_text
          title
          text
          button_text
          button_url
        }
        product_section {
          title
          text
          product_list {
            top_text
            title
            logo_image
            image
            tag
            button_text
            button_url
          }
        }
        innovation_section {
          flag_image
          medical_logo
          list {
            desktop_image
            mobile_image
            title
            text
            button_text
            button_url
          }
        }
        review_section {
          desktop_image
          mobile_image
          title
          button_text
          button_url
        }
        testimonial_section {
          desktop_image
          mobile_image
          title
          text
          button_text
          button_url
          list {
            image
            text
          }
        }
        expert_section {
          desktop_image
          mobile_image
          title
          text
          bottom_text
          logo_image
          button_text
          button_url
        }
        result_section {
          title
          text
          bottom_text
          button_text
          button_url
          list {
            title
            text
          }
        }
        instagram_section {
          title
          dekstop_image
          mobile_image
          instagram_logo
          instagram_text
          button_text
          button_url
          button_url_uk
        }
        video_section_text {
          title
          mobile_title
          button_text
          button_url
          medical_image
          innovation_image
        }
      }
    }
    allDirectusTvAdsSectionTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        video_title
        love_us_video_title
        video_text
        love_us_video_text
        video_url
        slider_title
        love_us_slider_title
        slider_text
        love_us_slider_text
        page_link_text
        slider {
          list {
            image
            text
            link
          }
        }
        slider_link_text
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        medicale_text
        medicale_logo {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        months_text {
          month
        }
        group_add_to_cart_title
        group_add_to_cart_save_text
        new_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    shopifyProduct(handle: { eq: "lyneup" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lyneup" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneUp))
