import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import styled from "@emotion/styled"

import {
  LyneupContainer,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  ProductShopSection,
  LyneShopImageSection,
  LyneupAddToCart,
  LyneAddToCartWrapper,
  LyneNoSizeText,
  DimensionText,
  Select,
  EcoParticipationText,
  MatelasComboWrapper,
  LyneComboBox,
  LyneComboBoxTagWrapper,
  LyneComboBoxTag,
  LyneComboBoxTitle,
  LyneComboBottomSection,
  LyneComboLeftSection,
  MatelasComboBoxImage,
  LyneCheckImage,
  MatelasShopBox,
  MatelasShopInfoSection,
  ClockImage,
} from "../../../components/Products/styled"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { navigate } from "@reach/router"
import PriceDetails from "../../../components/Products/PriceDetails"
import SurMatelasStickyAddToCart from "../../../components/Products/SurMatelasStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import { getPrice, navigateToCart } from "../../../utils/navigateToCart"
import LyneImageSlider from "../../../components/Products/LyneImageSlider"
import LyneTestimonialSection from "../../../components/Products/LyneTestimonialSection"
import SurmatelasImageSection from "../../../components/Products/SurmatelasImageSection"
import LyneUsageSection from "../../../components/Products/LyneUsageSection"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import LyneFaqSection from "../../../components/Products/LyneFaqSection"
import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import MatelasProductTopSection from "../../../components/Products/MatelasProductTopSection"
import SatisfactionClientSection from "../../../components/Products/SatisfactionClientSection"
import SommierInnovationSection from "../../../components/SommierInnovationSection"
import MatelasSainSection from "../../../components/Products/MatelasSainSection"
import SoutienDesSection from "../../../components/Products/SoutienDesSection"
import SurMatelasCompositionSection from "../../../components/Products/SurMatelasCompositionSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import MatelasAlmaSection from "../../../components/Products/MatelasAlmaSection"
import CoqIcon from "../../../../static/Coq.svg"
import GiftOfferSection from "../../../components/Products/GiftOfferSection"
import ChristmasCardPopup from "../../../components/Layout/ChristmasCardPopup"
import ShowroomWidget from "../../../components/Products/ShowroomWidget"

const SurMatelas = ({
  intl,
  data,
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({})
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [activeFlag, setactiveFlag] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [productPrice, setProductPrice] = useState(259)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [ecoParticipationPrice, setecoParticipationPrice] = useState("")
  const [showChristmasPopup, setShowChristmasPopup] = useState(false)

  const handleParamStrings = selectedOptions => {
    let paramString = ""

    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Size") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Size") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })

    isBrowser && history.replaceState(null, null, paramString)
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    setselectOptions(selectOptions => {
      let selectedOptions = { ...selectOptions }

      let product = ""

      if (target.name === "Size") {
        if (target.value) {
          selectedOptions[target.name] = target.value
        } else {
          selectedOptions[target.name] = ""
        }
      }

      product = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "surmatelas"
      )?.node

      setSelectedProduct(product)
      ecoParticipation(selectedOptions["Size"])

      removeErrors(selectedOptions)
      handleParamStrings(selectedOptions)

      if (Object.keys(selectedOptions).length === 1) {
        const selectedVariant = client.product.helpers.variantForOptions(
          product,
          selectedOptions
        )
        if (selectedVariant) {
          setselectedVariant(selectedVariant)
          setErrors({ exist: false, message: [] })
        } else setselectedVariant(null)
      }
      return selectedOptions
    })
  }

  const removeErrors = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "size_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error" }),
        ]
    }

    removeErrors(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            selectedProduct.id,
            selectedProduct.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sizeParam = urlParams.get("Size")
    let options = { ...selectOptions }

    let product = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "surmatelas"
    )?.node

    if (sizeParam) {
      options.Size = sizeParam
    } else {
      options.Size = "90x200cm"
      let paramString = ""
      paramString = paramString + "?Size=" + options.Size
      isBrowser && history.replaceState(null, null, paramString)
    }

    ecoParticipation(options.Size)

    setSelectedProduct(product)

    if (Object.keys(options).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    setselectOptions(options)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  let discountPercentage = 0.15

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant ? selectedVariant.price : productPrice
    )

    lyneDiscountPrice =
      Number(lyneDiscountPrice) -
      Number(lyneDiscountPrice) * Number(discountPercentage)

    return getPrice(lyneDiscountPrice)
  }

  const ecoParticipation = size => {
    if (size == "90x200cm") {
      setecoParticipationPrice("2,00€")
    } else if (size == "140x190cm") {
      setecoParticipationPrice("4,50€")
    } else {
      setecoParticipationPrice("7,50€")
    }
  }

  const mounted = useRef()
  var searchLocation = useLocation()

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
      productGtmEvent(
        selectedProduct,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        selectedProduct,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    let price = ""
    price = lyneDiscountPrice()
    setProductPrice(price)
  }, [selectedVariant])

  useEffect(() => {
    if (intl.locale != "fr" && process.env.GATSBY_CURRENCY_TYPE != "eur")
      navigate(`/${intl.locale}/shop/`)
  }, [intl.locale])

  return (
    process &&
    process.env &&
    process.env.GATSBY_CURRENCY_TYPE === "eur" &&
    intl.locale === "fr" &&
    data &&
    data.allDirectusSurMatelasTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="surmatelas"
          hreflangPathname={{
            fr: `shop/surmatelas`,
            en: `shop`,
            de: `shop`,
            es: `shop`,
          }}
        />

        <ShowroomWidget page="surmatelas" />

        <SurMatelasStickyAddToCart
          title={data.allDirectusSurMatelasTranslation.nodes[0].sticky_title}
          size={selectOptions["Size"]}
          cart_text={
            data.allDirectusSurMatelasTranslation.nodes[0].add_to_cart_button
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          regularPrice={Number(
            selectedVariant ? selectedVariant.price : productPrice
          ).toFixed(0)}
          discountPrice={lyneDiscountPrice()}
        />

        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />

        {data.allDirectusGiftCardPageTranslation.nodes[0].gift_card_popup && (
          <ChristmasCardPopup
            data={
              data.allDirectusGiftCardPageTranslation.nodes[0].gift_card_popup
            }
            show={showChristmasPopup}
            hide={setShowChristmasPopup}
          />
        )}
        <LyneupContainer
          style={{
            maxWidth: "1300px",
          }}
        >
          <ProductShopSection>
            <LyneShopImageSection>
              <SurmatelasImageSection
                data={
                  data.allDirectusSurMatelasTranslation.nodes[0].banner_slider
                    .list
                }
              />
            </LyneShopImageSection>
            <MatelasShopInfoSection page="surmatelas">
              <MatelasShopBox style={{ maxWidth: "520px" }}>
                <MatelasProductTopSection
                  title={data.allDirectusSurMatelasTranslation.nodes[0].title}
                  subtitle={
                    data.allDirectusSurMatelasTranslation.nodes[0].subtitle
                  }
                  description={
                    data.allDirectusSurMatelasTranslation.nodes[0].description
                  }
                  page="matelas"
                />
                <LyneImageSlider
                  data={
                    data.allDirectusSurMatelasTranslation.nodes[0].banner_slider
                      .list
                  }
                />
                <DimensionText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusSurMatelasTranslation.nodes[0]
                        .dimensions_text,
                  }}
                />

                <Select
                  id="pa_taille_classique"
                  name="Size"
                  pageName="matelas"
                  onChange={handleChangeSelectOptions}
                  value={selectOptions["Size"]}
                  error={!selectOptions["Size"] && Errors.exist}
                  style={{ width: "100%" }}
                >
                  {/* <option value="">
                    {intl.formatMessage({
                      id: "my_percko_matelas_size_text",
                    })}
                  </option> */}
                  {data.allDirectusSurMatelasTranslation.nodes[0].product_size.list.map(
                    (item, index) => (
                      <option
                        value={item.value}
                        key={`Size-${index.toString()}`}
                      >
                        {item.size}
                      </option>
                    )
                  )}
                </Select>
              </MatelasShopBox>
              <MatelasShopBox style={{ maxWidth: "520px" }}>
                <DimensionText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "lyne_product_select_text",
                    }),
                  }}
                  style={{ margin: "20px 0px 10px" }}
                />

                {!selectOptions["Size"] && Errors.exist && (
                  <LyneNoSizeText
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "size_not_seleceted_error",
                      }),
                    }}
                    error={!selectOptions["Size"] && Errors.exist}
                    style={{ margin: "0px 0px 10px 0px" }}
                  />
                )}
                <MatelasComboWrapper style={{ margin: "10px 0px 20px" }}>
                  <LyneComboBox border="0px">
                    <LyneComboBoxTagWrapper>
                      <LyneComboBoxTag lang={intl.locale}>
                        SOLDES
                        {/* <span>
                          Derniers
                          <br />
                          jours
                        </span>
                        <ClockImage src={CoqIcon} /> */}
                      </LyneComboBoxTag>
                    </LyneComboBoxTagWrapper>
                    {/* <LyneComboBoxTitle
                      dangerouslySetInnerHTML={{
                        __html: "<strong>1 surmatelas</strong>",
                      }}
                    /> */}
                    <LyneComboBoxTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          "<strong>1 surmatelas <span>-15%</span></strong>",
                      }}
                    />
                    <LyneComboBottomSection>
                      <LyneComboLeftSection>
                        <MatelasComboBoxImage
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/3b57f26e-cb0d-4b44-8cc4-dee2a3367599.png"
                        />
                        <ProductDiscountPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${lyneDiscountPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductDiscountPrice>
                        <ProductStrikePrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : productPrice
                          )}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductStrikePrice>
                        {/* <ProductRegularPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : productPrice
                          )}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductRegularPrice> */}
                      </LyneComboLeftSection>
                      <LyneCheckImage
                        className="lazyload"
                        data-src="https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                      />
                    </LyneComboBottomSection>
                  </LyneComboBox>

                  {/* {data.allDirectusGiftCardPageTranslation.nodes[0]
                    .gift_card_popup && (
                    <GiftOfferSection
                      show={setShowChristmasPopup}
                      price={lyneDiscountPrice()}
                      page="surmatelas"
                    />
                  )} */}
                </MatelasComboWrapper>

                <LyneAddToCartWrapper page="surmatelas">
                  <LyneupAddToCart
                    id="add_to_cart"
                    loading={storeContext.adding ? "adding" : ""}
                    onClick={() => handleAddTocart()}
                  >
                    {
                      data.allDirectusSurMatelasTranslation.nodes[0]
                        .add_to_cart_button
                    }
                  </LyneupAddToCart>
                </LyneAddToCartWrapper>

                {selectOptions["Size"] && (
                  <ProductEstimateShippingBox page="matelas">
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "surmatelas",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale,
                        selectOptions["Size"]
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
                {ecoParticipationPrice && (
                  <EcoParticipationText
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: "eco_paricipation_text",
                        })
                        .replace("eco_price", ecoParticipationPrice),
                    }}
                  />
                )}
                {productPrice && selectedVariant && (
                  <MatelasAlmaSection price={lyneDiscountPrice()} />
                )}

                {data.allDirectusSurMatelasTranslation.nodes[0]
                  .testimonial_section && (
                  <LyneTestimonialSection
                    data={
                      data.allDirectusSurMatelasTranslation.nodes[0]
                        .testimonial_section
                    }
                    page="surmatelas"
                  />
                )}
              </MatelasShopBox>
            </MatelasShopInfoSection>
          </ProductShopSection>
        </LyneupContainer>

        {data.allDirectusSurMatelasTranslation.nodes[0].page_section_list && (
          <LyneupPageSectionList
            data={
              data.allDirectusSurMatelasTranslation.nodes[0].page_section_list
            }
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0].confort_section && (
          <SoutienDesSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0].confort_section
            }
            id="confort_section"
            page="surmatelas"
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0].technology_section && (
          <SurMatelasCompositionSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0].technology_section
            }
            id="technology_section"
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0]
          .product_sain_section && (
          <MatelasSainSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0]
                .product_sain_section
            }
            id="product_sain_section"
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0]
          .satisfaction_section && (
          <SatisfactionClientSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0]
                .satisfaction_section
            }
            id="satisfaction_client"
            page="surmatelas"
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0].innovation_section && (
          <SommierInnovationSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0].innovation_section
            }
            id="innovation_section"
            page="surmatelas"
          />
        )}

        {data.allDirectusSurMatelasTranslation.nodes[0].usage_section && (
          <LyneUsageSection
            data={data.allDirectusSurMatelasTranslation.nodes[0].usage_section}
            page="surmatelas"
          />
        )}

        {intl.locale === "fr" &&
          data.allDirectusSurMatelasTranslation.nodes[0].cross_section && (
            <CrossProductSection
              data={
                data.allDirectusSurMatelasTranslation.nodes[0].cross_section
              }
              price={data.allShopifyProduct.edges}
              id="cross_section"
              page="surmatelas"
            />
          )}

        {data.allDirectusSurMatelasTranslation.nodes[0].product_faq_section && (
          <LyneFaqSection
            data={
              data.allDirectusSurMatelasTranslation.nodes[0].product_faq_section
            }
            title={
              data.allDirectusSurMatelasTranslation.nodes[0].product_faq_section
                .title
            }
            subTitle={
              data.allDirectusSurMatelasTranslation.nodes[0].product_faq_section
                .subtitle
            }
            id="faq_section"
          />
        )}

        {data.allDirectusCommonTranslation.nodes[0].testimonials &&
          data.allDirectusCommonTranslation.nodes[0].testimonials.list && (
            <Testimonials
              data={
                data.allDirectusCommonTranslation.nodes[0].testimonials.list
              }
            />
          )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const ProductStrikePrice = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;
  font-weight: 500;
  position: relative;

  :before {
    position: absolute;
    content: "";
    border-top: 1px solid #262626;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`

const ProductDiscountPrice = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: ${props => props.theme.saleColor.priceColor};
  font-weight: bold;
  position: relative;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 7px;
  }
`

const ProductRegularPrice = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;
  font-weight: 500;
  position: relative;
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusSurMatelasTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        subtitle
        description
        dimensions_text
        banner_slider {
          list {
            img
            alt
          }
        }
        product_size {
          list {
            size
            value
          }
        }
        add_to_cart_button
        sticky_title
        testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        confort_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          desktop_image
          mobile_image
          image_alt
        }
        technology_section {
          section_title
          image
          mobile_image
          alt
          list {
            title
            text
            tech_list {
              icon_image
              icon_alt
              text
              popup_text
            }
          }
        }
        product_sain_section {
          section_title
          section_mobile_title
          bottom_text
          mobile_bottom_text
          list {
            image
            alt
            title
            text
            top_image
            top_image_alt
          }
        }
        satisfaction_section {
          title
          subtitle
          mobile_subtitle
        }
        innovation_section {
          title
          review_list {
            image
            mobile_image
            alt
            quote_image
            name
            designation
            company
            city
            text
          }
        }
        usage_section {
          title
          subtitle
          video_url
          list {
            image
            image_alt
          }
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          bottom_text
          button_text
          url
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        alma_popup_text
      }
    }
    allDirectusGiftCardPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        gift_card_popup {
          title
          text
          list {
            image
            image_alt
            text
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusReviewTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          product
          name
          stars
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "sur_matelas" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(SurMatelas))
