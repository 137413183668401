import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"

import {
  LyneupContainer,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  LyneupSizeErrorBox,
  ProductShopSection,
  LyneShopImageSection,
  LyneShopInfoSection,
  LyneupAddToCart,
  LyneAddToCartWrapper,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductAlmaDetail,
  LyneNoSizeText,
  LyneOldBottomText,
  LyneProductDescription,
  LyneReviewText,
} from "../../../components/Products/styled"

import { ProductSizeVariationBoxWrapper } from "../../../components/Products/ProductSizeVariationBox"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import {
  CapitalizeFirstLetter,
  colors,
  genders,
  LyneUpNewImageSliders,
  LyneUpComboImage,
  colorsReverse,
  lyneProductType,
  lyneProductTypeReverse,
  OutOfStockItems,
} from "../../../utils/lyneUphelpers"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { navigate } from "@reach/router"
import PriceDetails from "../../../components/Products/PriceDetails"
import LyneupSizeGuidePopup from "../../../components/Products/LyneupSizeGuidePopup"
import LyneStickyAddToCart from "../../../components/Products/LyneStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addLyneHomeUrl,
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import {
  SizeSelectorLyneUpFemale,
  SizeSelectorLyneUpMale,
} from "../../../components/SizeSelector"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import {
  getPrice,
  monthlyPrice,
  navigateToCart,
} from "../../../utils/navigateToCart"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import LyneTrustPilotReviewSection from "../../../components/Products/LyneTrustPilotReviewSection"
import LyneProductTopSection from "../../../components/Products/LyneProductTopSection"
import LyneImageSlider from "../../../components/Products/LyneImageSlider"
import LyneGenderSection from "../../../components/Products/LyneGenderSection"
import LyneColorSection from "../../../components/Products/LyneColorSection"
import LyneTestimonialSection from "../../../components/Products/LyneTestimonialSection"
import LyneComboSection from "../../../components/Products/LyneComboSection"
import LyneupSizeSelectorSection from "../../../components/Products/LyneupSizeSelectorSection"
import LyneImageSection from "../../../components/Products/LyneImageSection"
import LyneupSizeGuideModal from "../../../components/Products/LyneupSizeGuideModal"
import LyneProductType from "../../../components/Products/LyneProductType"
import { LyneHomeNewImageSliders } from "../../../utils/lyneHomeHelper"
import { LyneFitNewImageSliders } from "../../../utils/lyneFitHelper"
import LyneSizeSelectorSection from "../../../components/Products/LyneSizeSelectorSection"
import LyneSizeGuidePopup from "../../../components/Products/LyneSizeGuidePopup"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import LyneHighlightSection from "../../../components/Products/LyneHighlightSection"
import LyneTechnologySection from "../../../components/Products/LyneTechnologySection"
import LyneReviewSection from "../../../components/Products/LyneReviewSection"
import LyneUsageSection from "../../../components/Products/LyneUsageSection"
import LyneMedicalSection from "../../../components/Products/LyneMedicalSection"
import LyneComparisonSection from "../../../components/Products/LyneComparisonSection"
import LyneFaqSection from "../../../components/Products/LyneFaqSection"
import MatelasAlmaSection from "../../../components/Products/MatelasAlmaSection"

const LyneUp = ({
  intl,
  data,
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({
    Gender: "",
    Color: "",
    Size: "",
  })
  const [openModal, setopenModal] = useState(false)
  const [openSizeModal, setopenSizeModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [activeFlag, setactiveFlag] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [menSizeParameters, setmenSizeParameters] = useState({
    Age: "",
    Height: "",
    Weight: "",
  })

  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [recommendation, setRecommendation] = useState({})
  const [selectProductType, setSelectProductType] = useState("lyneup")
  const [selectOfferType, setSelectOfferType] = useState("Seul")
  const [productPrice, setProductPrice] = useState(129)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [userSelectProduct, setUserSelectProduct] = useState("")

  const handleQuantityChange = event => {
    setselectedVariantQuantity(event.target.value)
  }

  const handleParamStrings = (selectedOptions, productType, offerType) => {
    let paramString = ""
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    if (productType) {
      let chooseProductType = lyneProductTypeReverse[intl.locale][productType]
      paramString = paramString + "&Type" + "=" + chooseProductType
    }
    if (offerType) {
      paramString = paramString + "&Offer" + "=" + offerType
    }
    isBrowser && history.replaceState(null, null, paramString)
  }

  const removeErrors = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      let selectedOptions = { ...selectOptions }

      if (data.recommendation.size?.length) {
        selectedOptions["Size"] = data.recommendation.size
        setselectOptions(selectedOptions)

        handleChangeSelectOptions({
          target: { name: "Size", value: data.recommendation.size },
        })

        setRecommendation(data)

        if (selectOptions["Gender"] === "Male") {
          setmenSizeParameters(data)
        }

        seterrorSize("")
      } else {
        selectedOptions["Size"] = ""
        setselectOptions(selectedOptions)

        handleChangeSelectOptions({
          target: { name: "Size", value: "" },
        })
        seterrorSize(
          intl
            .formatMessage({
              id: "size_selector.lyneup_size_error_message",
            })
            .replace(
              "/shop/",
              addLyneHomeUrl(intl.locale, selectOptions["Gender"])
            )
        )
      }
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    setselectOptions(selectOptions => {
      let selectedOptions = { ...selectOptions }

      let productType = selectProductType
      let product = ""

      if (target.name === "Color") {
        selectedOptions[target.name] = colors[intl.locale][target.value]
      } else if (target.name === "Gender") {
        selectedOptions[target.name] = genders[intl.locale][target.value]
        seterrorSize("")
        if (productType === "lynefit") {
          selectedOptions["Size"] = ""
        }
        if (!selectOptions["Color"]) {
          selectedOptions["Color"] = "BLACK"
        }
      } else if (target.name === "Size") {
        if (target.value) {
          selectedOptions[target.name] = target.value
        } else {
          selectedOptions[target.name] = ""
        }
      } else if (target.name === "Type") {
        productType = target.value
      }

      product = data.allShopifyProduct?.edges.find(
        item => item.node.handle === productType
      )?.node

      setSelectedProduct(product)
      setUserSelectProduct(productType)

      removeErrors(selectedOptions)
      handleParamStrings(selectedOptions, productType, selectOfferType)

      if (Object.keys(selectedOptions).length === 3) {
        const selectedVariant = client.product.helpers.variantForOptions(
          product,
          selectedOptions
        )
        if (selectedVariant) {
          setselectedVariant(selectedVariant)
          setErrors({ exist: false, message: [] })
        } else setselectedVariant(null)
      }
      return selectedOptions
    })
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "size_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error" }),
        ]
    }

    removeErrors(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    let flatInput = recommendation?.inputs
      ? Object.keys(recommendation.inputs).map(key => ({
          key: key,
          value: `${recommendation.inputs[key]}`,
        }))
      : []

    let flatRecommendation = recommendation?.recommendation
      ? Object.keys(recommendation.recommendation).map(key => ({
          key: key,
          value: `${recommendation.recommendation[key]}`,
        }))
      : []
    // prepare attributes
    let attributes = [...flatInput, ...flatRecommendation]

    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        attributes,
        checkout => {
          addToCartScript(
            selectedProduct.id,
            selectedProduct.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    let productType = urlParams.get("Type")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    const offerType = urlParams.get("Offer")
    let options = { ...selectOptions }
    let product = ""

    if (genderParam) {
      options.Gender = genderParam
      if (
        productType &&
        lyneProductType[intl.locale][productType] === "lynehome"
      ) {
        options.Color = "BLUE"
      } else if (
        productType &&
        lyneProductType[intl.locale][productType] === "lynefit"
      ) {
        options.Color = "BLACK MICRO-PERFORATED"
      } else {
        options.Color = "BLACK"
      }
    }

    if (colorParam) {
      options.Color = colorParam
    }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (productType) {
      productType = lyneProductType[intl.locale][productType]
      setSelectProductType(productType)
      setUserSelectProduct(productType)
      product = data.allShopifyProduct?.edges.find(
        item => item.node.handle === productType
      )?.node
    } else {
      product = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "lyneup"
      )?.node
    }

    setSelectedProduct(product)

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      selectedVariant && setProductPrice(selectedVariant.price)
      setselectedVariant(selectedVariant)
    }

    if (offerType) {
      let price = ""
      if (offerType === "Pack") {
        price = lyneGroupDiscountPrice()
        setselectedVariantQuantity(2)
      } else {
        price = lyneDiscountPrice()
        setselectedVariantQuantity(1)
      }

      // setProductPrice(price)
      setSelectOfferType(offerType)
    }
    setselectOptions(options)
  }

  const handleScrolling = () => {
    $(".sticy-lyneup-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        if (!selectOptions["Gender"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_gender_section").offset().top - 150,
            },
            1500
          )
        } else if (!selectOptions["Size"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_size_section").offset().top - 150,
            },
            1500
          )
        }
      } else {
        if (!selectOptions["Gender"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_gender_section").offset().top - 200,
            },
            1500
          )
        } else if (!selectOptions["Size"]) {
          $("html, body").animate(
            {
              scrollTop: $("#lyne_size_section").offset().top - 150,
            },
            1500
          )
        }
      }
      handleErrors()
    })
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const returnSizeSelector = mobile => {
    return (
      <>
        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Male"}
          mobile={mobile}
          style={{ margn: "10px 0px" }}
        >
          <SizeSelectorLyneUpMale
            setLoading={setLoading}
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        <ProductSizeVariationBoxWrapper
          show={selectOptions["Gender"] === "Female"}
          mobile={mobile}
          style={{ margn: "10px 0px" }}
        >
          <SizeSelectorLyneUpFemale
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        {/* {errorSize && (
          <LyneupSizeErrorBox dangerouslySetInnerHTML={{ __html: errorSize }} />
        )} */}
      </>
    )
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const handleOfferType = offerType => {
    let price = ""
    if (offerType === "Pack") {
      price = lyneGroupDiscountPrice()
      setselectedVariantQuantity(2)
    } else {
      price = lyneDiscountPrice()
      setselectedVariantQuantity(1)
    }

    let selectedOptions = { ...selectOptions }
    handleParamStrings(selectedOptions, selectProductType, offerType)

    // setProductPrice(price)

    productGtmEvent(
      selectedProduct,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
    setSelectOfferType(offerType)
  }

  const handleProductType = productType => {
    let color = ""
    if (productType === "lynehome") {
      color = "BLUE"
    } else if (productType === "lynefit") {
      color = "BLACK MICRO-PERFORATED"
    } else {
      color = "BLACK"
    }

    setSelectProductType(productType)
    setUserSelectProduct(productType)

    handleChangeSelectOptions({
      target: { name: "Size", value: "" },
    })

    handleChangeSelectOptions({
      target: { name: "Color", value: colorsReverse[intl.locale][color] },
    })

    handleChangeSelectOptions({
      target: { name: "Type", value: productType },
    })
  }

  let discountPercentage = 0.25

  const lyneDiscountPrice = () => {
    let lyneDiscountPrice = Number(
      selectedVariant ? selectedVariant.price : productPrice
    )

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      // lyneDiscountPrice = Number(lyneDiscountPrice) - 15
    } else {
      // lyneDiscountPrice =
      //   Number(lyneDiscountPrice) -
      //   Number(lyneDiscountPrice) * Number(discountPercentage)
      lyneDiscountPrice = Number(lyneDiscountPrice) - 30
    }

    return getPrice(lyneDiscountPrice)
  }

  const lyneGroupPrice = () => {
    let lyneGroupPrice = Number(
      selectedVariant ? selectedVariant.price : productPrice
    )

    lyneGroupPrice = Number(lyneGroupPrice) * 2

    return getPrice(lyneGroupPrice)
  }

  const lyneGroupDiscountPrice = () => {
    let LyneGroupDiscountPrice = lyneGroupPrice()

    if (process.env.GATSBY_CURRENCY_TYPE === "gbp") {
      discountPercentage = 0.125
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 50
    } else {
      LyneGroupDiscountPrice =
        Number(LyneGroupDiscountPrice) -
        Number(LyneGroupDiscountPrice) * Number(discountPercentage)
      // LyneGroupDiscountPrice = Number(LyneGroupDiscountPrice) - 60
    }

    return getPrice(LyneGroupDiscountPrice)
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
      productGtmEvent(
        selectedProduct,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        selectedProduct,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    if (process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr") {
      navigate(`/${process.env.GATSBY_ACTIVE_ENV}/shop/solutions-textiles/`)
    }
    if (intl.locale === "en") {
      navigate(
        `/${process.env.GATSBY_ACTIVE_ENV}/${intl.locale}/shop/lyneup-test`
      )
    }
    if (intl.locale === "de" || intl.locale === "es") {
      navigate(
        `/${process.env.GATSBY_ACTIVE_ENV}/${intl.locale}/shop/lyneup-2b/`
      )
    }
  }, [intl.locale])

  return (
    process &&
    process.env &&
    process.env.GATSBY_CURRENCY_TYPE === "eur" &&
    intl.locale === "fr" &&
    data &&
    data.allDirectusLyneupTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="wearable-technology-last-chance"
          productType={userSelectProduct}
          hreflangPathname={{
            fr: `shop/solutions-textiles-derniere-chance`,
            en: `shop/lyneup-test`,
            de: `shop/lyneup-2b`,
            es: `shop/lyneup-2b`,
          }}
          noindex={true}
          nofollow={true}
        />
        <LyneStickyAddToCart
          gender={selectOptions["Gender"]}
          color={selectOptions["Color"]}
          size={selectOptions["Size"]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_size",
                })
              : data.allDirectusLyneupTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          regularPrice={
            selectedVariantQuantity == 1
              ? Number(
                  selectedVariant ? selectedVariant.price : productPrice
                ).toFixed(0)
              : lyneGroupPrice()
          }
          discountPrice={
            selectedVariantQuantity == 1
              ? lyneDiscountPrice()
              : lyneGroupDiscountPrice()
          }
          productType={selectProductType}
          quantity={selectedVariantQuantity}
        />
        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />

        <LyneSizeGuidePopup
          sizeChart={
            selectProductType === "lynefit"
              ? data.allDirectusLynefitTranslation.nodes[0]
              : data.allDirectusLyneHomeTranslation.nodes[0]
          }
          sizeText={
            selectProductType === "lynefit"
              ? data.allDirectusLynefitTranslation.nodes[0].size_chart_text
              : data.allDirectusLyneHomeTranslation.nodes[0].size_chart_text
          }
          gender={selectOptions["Gender"]}
          productType={selectProductType}
          page={selectProductType}
          open={selectProductType != "lyneup" && openSizeModal}
          closeHandle={setopenSizeModal}
        />

        {!IsMobile ? (
          <LyneupSizeGuidePopup
            data={data.allDirectusLyneupTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            gender={selectOptions["Gender"]}
            open={selectProductType === "lyneup" && openSizeModal}
            closeHandle={setopenSizeModal}
          />
        ) : (
          <LyneupSizeGuideModal
            data={data.allDirectusLyneupNewPageTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            size_data={data.allDirectusLyneupTranslation.nodes[0].size}
            color_data={data.allDirectusLyneupTranslation.nodes[0].color}
            gender={selectOptions["Gender"]}
            productType={selectProductType}
            open={selectProductType === "lyneup" && openSizeModal}
            closeHandle={setopenSizeModal}
            addToCart={handleAddTocart}
            handleSelectOptions={handleChangeSelectOptions}
            addToCartText={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_add_to_cart_button
            }
            size={selectOptions["Size"]}
            color={colorsReverse[intl.locale][selectOptions["Color"]]}
            formatText={CapitalizeFirstLetter}
            quantity={selectedVariantQuantity}
            quantitySelector={setselectedVariantQuantity}
            handleQuantity={handleQuantityChange}
            variant={selectedVariant}
            material_info={
              data.allDirectusLyneupTranslation.nodes[0].product_material_info
            }
            setopenSizeModal={setopenSizeModal}
            selectOptions={selectOptions}
            Errors={Errors}
            returnSizeSelector={returnSizeSelector}
            one_size_system_text={
              data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
            }
            morphology_text={
              data.allDirectusLyneupTranslation.nodes[0].morphology_text
            }
            i_calculate_text={
              data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
            }
            most_suited_text={
              data.allDirectusLyneupTranslation.nodes[0].most_suited_text
            }
            edit_my_information_text={
              data.allDirectusLyneupTranslation.nodes[0]
                .edit_my_information_text
            }
            return_text={data.allDirectusLyneupTranslation.nodes[0].return_text}
            menSizeOptions={menSizeParameters}
            menerrorSize={errorSize}
            setmenerrorSize={seterrorSize}
            price={Number(
              selectedVariant ? selectedVariant.price : productPrice
            ).toFixed(0)}
            selectedVariantQuantity={setselectedVariantQuantity}
            estimateDeliveryText={
              data.allDirectusCommonTranslation.nodes[0].months_text
            }
            loading={storeContext.adding ? "adding" : ""}
          />
        )}
        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <LyneupContainer
          style={{
            maxWidth: "1300px",
          }}
        >
          <ProductShopSection>
            <LyneShopImageSection>
              <LyneImageSection
                data={
                  selectProductType === "lynehome"
                    ? LyneHomeNewImageSliders
                    : selectProductType === "lynefit"
                    ? LyneFitNewImageSliders
                    : LyneUpNewImageSliders
                }
                gender={selectOptions["Gender"]}
                color={selectOptions["Color"]}
                medicaleLogo={
                  data.allDirectusLyneup2BPageTranslation.nodes[0]
                    .innovation_section.medical_image
                }
                productType={selectProductType}
                page="old-lyne"
              />
            </LyneShopImageSection>
            <LyneShopInfoSection>
              <LyneProductTopSection
                regularPrice={Number(
                  selectedVariant ? selectedVariant.price : productPrice
                ).toFixed(0)}
                discountPrice={lyneDiscountPrice()}
                title={data.allDirectusLyneupPageTranslation.nodes[0].title}
                openAlma={setopenAlmaModal}
                page="old-lyne"
              />

              {data.allDirectusLynePageTranslation.nodes[0].description && (
                <LyneProductDescription
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusLynePageTranslation.nodes[0].description,
                  }}
                />
              )}

              <LyneGenderSection
                data={data.allDirectusLyneupTranslation.nodes[0].gender.item}
                handleChange={handleChangeSelectOptions}
                gender={selectOptions["Gender"]}
                error={selectOptions["Gender"] ? null : Errors.exist}
                id="lyne_gender_section"
              />

              {selectOptions["Gender"] && (
                <LyneProductType
                  data={
                    data.allDirectusLyneupPageTranslation.nodes[0]
                      .product_section
                  }
                  handleChange={handleProductType}
                  productType={selectProductType}
                  gender={selectOptions["Gender"]}
                  error={selectProductType ? null : Errors.exist}
                  page="old-lyne"
                />
              )}

              {selectOptions["Gender"] && selectOptions["Color"] && (
                <Fragment>
                  <LyneColorSection
                    data={
                      selectProductType === "lynehome"
                        ? data.allDirectusLyneupPageTranslation.nodes[0]
                            .lynehome_color.item
                        : selectProductType === "lynefit"
                        ? data.allDirectusLyneupPageTranslation.nodes[0]
                            .lynefit_color.item
                        : data.allDirectusLyneupTranslation.nodes[0].color.item
                    }
                    handleChange={handleChangeSelectOptions}
                    disabled={!(selectOptions && selectOptions["Gender"])}
                    selectedColor={selectOptions["Color"]}
                    productType={selectProductType}
                  />

                  {selectOptions["Gender"] && selectOptions["Color"] && (
                    <LyneImageSlider
                      data={
                        selectProductType === "lynehome"
                          ? LyneHomeNewImageSliders[selectOptions["Gender"]][
                              selectOptions["Color"]
                            ]
                          : selectProductType === "lynefit"
                          ? LyneFitNewImageSliders[selectOptions["Gender"]][
                              selectOptions["Color"]
                            ]
                          : LyneUpNewImageSliders[selectOptions["Gender"]][
                              selectOptions["Color"]
                            ]
                      }
                    />
                  )}

                  {selectProductType === "lynehome" ||
                  selectProductType === "lynefit" ? (
                    <LyneSizeSelectorSection
                      data={
                        selectProductType === "lynehome"
                          ? data.allDirectusLyneupPageTranslation.nodes[0]
                              .lynehome_size[selectOptions["Gender"]]
                          : data.allDirectusLyneupPageTranslation.nodes[0]
                              .lynefit_size[selectOptions["Gender"]]
                      }
                      productType={selectProductType}
                      gender={selectOptions["Gender"]}
                      color={selectOptions["Color"]}
                      size={selectOptions["Size"]}
                      openSizeModal={setopenSizeModal}
                      handleChange={handleChangeSelectOptions}
                      error={!selectOptions["Size"] && Errors.exist}
                      id="lyne_size_section"
                    />
                  ) : (
                    <LyneupSizeSelectorSection
                      data={data.allDirectusLyneupTranslation.nodes[0].size}
                      productType={selectProductType}
                      gender={selectOptions["Gender"]}
                      color={selectOptions["Color"]}
                      size={selectOptions["Size"]}
                      openSizeModal={setopenSizeModal}
                      returnSizeSelector={returnSizeSelector}
                      handleChange={handleChangeSelectOptions}
                      error={!selectOptions["Size"] && Errors.exist}
                      errorSize={errorSize}
                      id="lyne_size_section"
                    />
                  )}

                  {selectOptions["Gender"] &&
                    selectOptions["Color"] &&
                    data.allDirectusLyneupTranslation.nodes[0]
                      .combo_section && (
                      <LyneComboSection
                        data={
                          data.allDirectusLyneupTranslation.nodes[0]
                            .combo_section
                        }
                        images={LyneUpComboImage}
                        gender={selectOptions["Gender"]}
                        color={selectOptions["Color"]}
                        size={selectOptions["Size"]}
                        error={!selectOptions["Size"] && Errors.exist}
                        offerType={selectOfferType}
                        productType={selectProductType}
                        handle={handleOfferType}
                        regularPrice={
                          selectedVariant ? selectedVariant.price : productPrice
                        }
                        discountPrice={lyneDiscountPrice()}
                        bundleRegularPrice={lyneGroupPrice()}
                        bundleDiscountPrice={lyneGroupDiscountPrice()}
                        page="old-lyne"
                      />
                    )}
                </Fragment>
              )}

              <div>
                {!selectOptions["Size"] && Errors.exist && (
                  <LyneNoSizeText
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "size_not_seleceted_error",
                      }),
                    }}
                    error={!selectOptions["Size"] && Errors.exist}
                    style={{ margin: "0px 0px 10px 0px" }}
                  />
                )}
                <LyneAddToCartWrapper>
                  <LyneupAddToCart
                    id="add_to_cart"
                    loading={storeContext.adding ? "adding" : ""}
                    onClick={() =>
                      selectProductType &&
                      selectOptions["Gender"] &&
                      selectOptions["Color"] &&
                      selectOptions["Size"] &&
                      OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                        selectProductType
                      ][selectOptions["Gender"]][
                        selectOptions["Color"]
                      ]?.includes(selectOptions["Size"])
                        ? ""
                        : handleAddTocart()
                    }
                    disabled={
                      selectProductType &&
                      selectOptions["Gender"] &&
                      selectOptions["Color"] &&
                      selectOptions["Size"] &&
                      OutOfStockItems[process.env.GATSBY_CURRENCY_TYPE][
                        selectProductType
                      ][selectOptions["Gender"]][
                        selectOptions["Color"]
                      ]?.includes(selectOptions["Size"])
                        ? true
                        : false
                    }
                  >
                    {
                      data.allDirectusLyneupTranslation.nodes[0]
                        .product_add_to_cart_button
                    }
                  </LyneupAddToCart>
                </LyneAddToCartWrapper>

                <LyneOldBottomText
                  dangerouslySetInnerHTML={{
                    __html: "Profitez avant qu'il n'y en ait plus",
                  }}
                />

                {/* {productPrice && (
                  <ProductAlmaPriceWrapper style={{ flexDirection: "row" }}>
                    <ProductAlmaPrice
                      style={{ marginRight: "5px", fontSize: "14px" }}
                    >
                      aussi payable en 3 x{" "}
                      <span>
                        {monthlyPrice(
                          selectOfferType === "Pack"
                            ? lyneGroupDiscountPrice()
                            : lyneDiscountPrice()
                        )}
                      </span>
                    </ProductAlmaPrice>
                    <ProductAlmaDetail
                      onClick={() => setopenAlmaModal(!openAlmaModal)}
                    >
                      {intl.formatMessage({
                        id: "alma_details_text",
                      })}
                    </ProductAlmaDetail>
                  </ProductAlmaPriceWrapper>
                )} */}

                {productPrice && selectedProduct && (
                  <MatelasAlmaSection
                    price={
                      selectedVariantQuantity == 1
                        ? lyneDiscountPrice()
                        : lyneGroupDiscountPrice()
                    }
                    page="solution-textile"
                  />
                )}

                {selectOptions["Size"] && (
                  <ProductEstimateShippingBox>
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "LyneUp",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale,
                        selectOptions["Size"],
                        selectOptions["Gender"],
                        selectOptions["Color"]
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
                {intl.locale === "fr" && (
                  <LyneReviewText
                    dangerouslySetInnerHTML={{
                      __html:
                        "*Basé sur les avis Trustpilot entre août 2021 et juillet 2022.",
                    }}
                  />
                )}
              </div>

              {data.allDirectusCommonTranslation.nodes[0]
                .lyne_testimonial_section && (
                <LyneTestimonialSection
                  data={
                    data.allDirectusCommonTranslation.nodes[0]
                      .lyne_testimonial_section
                  }
                  gender={selectOptions["Gender"] ? true : false}
                />
              )}
            </LyneShopInfoSection>
          </ProductShopSection>
        </LyneupContainer>

        {data.allDirectusLyneupPageTranslation.nodes[0].page_section_list && (
          <LyneupPageSectionList
            data={
              data.allDirectusLyneupPageTranslation.nodes[0].page_section_list
            }
          />
        )}

        {data.allDirectusLyneupPageTranslation.nodes[0].highlight_section && (
          <LyneHighlightSection
            data={
              data.allDirectusLyneupPageTranslation.nodes[0].highlight_section
            }
            id="highlights"
          />
        )}

        {data.allDirectusLyneupPageTranslation.nodes[0].technology_section && (
          <LyneTechnologySection
            data={
              data.allDirectusLyneupPageTranslation.nodes[0].technology_section
            }
            id="technology"
          />
        )}

        {data.allDirectusLyneupPageTranslation.nodes[0].medical_section && (
          <LyneMedicalSection
            data={
              data.allDirectusLyneupPageTranslation.nodes[0].medical_section
            }
            id="medical_device"
          />
        )}

        {data.allDirectusLyneupPageTranslation.nodes[0].usage_section && (
          <LyneUsageSection
            data={data.allDirectusLyneupPageTranslation.nodes[0].usage_section}
            id="usage"
          />
        )}

        <LyneComparisonSection id="comparison_section" />

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusReviewTranslation.nodes && (
            <LyneTrustPilotReviewSection
              data={data.allDirectusReviewTranslation.nodes}
              title={
                data.allDirectusLyneupPageTranslation.nodes[0].review_title
              }
              subTitle={
                data.allDirectusLyneupPageTranslation.nodes[0].review_subtitle
              }
              productType={userSelectProduct}
              id="review_section"
            />
          )}

        {(intl.locale === "fr" || intl.locale === "en") &&
          data.allDirectusLyneupPageTranslation.nodes[0].review_section && (
            <LyneReviewSection
              data={
                data.allDirectusLyneupPageTranslation.nodes[0].review_section
              }
            />
          )}

        {data.allDirectusLyneupPageTranslation.nodes[0].product_faq_section &&
          data.allDirectusLyneupPageTranslation.nodes[0].lyneup_faq_section &&
          data.allDirectusLyneupPageTranslation.nodes[0].lynehome_faq_section &&
          data.allDirectusLyneupPageTranslation.nodes[0]
            .lynefit_faq_section && (
            <LyneFaqSection
              data={
                userSelectProduct === "lynehome"
                  ? data.allDirectusLyneupPageTranslation.nodes[0]
                      .lynehome_faq_section
                  : userSelectProduct === "lynefit"
                  ? data.allDirectusLyneupPageTranslation.nodes[0]
                      .lynefit_faq_section
                  : userSelectProduct === "lyneup"
                  ? data.allDirectusLyneupPageTranslation.nodes[0]
                      .lyneup_faq_section
                  : data.allDirectusLyneupPageTranslation.nodes[0]
                      .product_faq_section
              }
              title={
                data.allDirectusLyneupPageTranslation.nodes[0].product_faq_title
              }
              subTitle={
                data.allDirectusLyneupPageTranslation.nodes[0]
                  .product_faq_subtitle
              }
              id="faq"
            />
          )}

        {intl.locale === "fr" &&
          data.allDirectusLyneupPageTranslation.nodes[0].cross_section && (
            <CrossProductSection
              data={
                data.allDirectusLyneupPageTranslation.nodes[0].cross_section
              }
              price={data.allShopifyProduct.edges}
              id="other_products"
            />
          )}

        {data.allDirectusCommonTranslation.nodes[0].testimonials &&
          data.allDirectusCommonTranslation.nodes[0].testimonials.list && (
            <Testimonials
              data={
                data.allDirectusCommonTranslation.nodes[0].testimonials.list
              }
            />
          )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLynePageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        description
      }
    }
    allDirectusLyneupPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        product_section {
          list {
            male_image
            female_image
            male_image_alt
            female_image_alt
            male_title
            female_title
            type
            name
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        highlight_section {
          title
          subtitle
          list {
            image
            image_alt
            text
          }
        }
        technology_section {
          title
          subtitle
          list {
            title
            image
            mobile_image
            image_alt
          }
        }
        medical_section {
          title
          subtitle
          image
          image_alt
          top_left_text
          top_right_text
          top_text
          bottom_text
          bottom_image
        }
        usage_section {
          title
          subtitle
          list {
            image
            image_alt
          }
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          bottom_text
          button_text
          url
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
        review_title
        review_subtitle
        review_section {
          title
          review_list {
            image
            name
            designation
            company
            city
            text
          }
          media_title
          media_list {
            image
            text
          }
        }
        product_faq_title
        product_faq_subtitle
        product_faq_section {
          list {
            question
            answer {
              image
              mobile_image
              image_alt
              text
            }
            open
          }
        }
        lyneup_faq_section {
          list {
            question
            answer {
              image
              mobile_image
              image_alt
              text
            }
            open
          }
        }
        lynehome_faq_section {
          list {
            question
            answer {
              image
              mobile_image
              image_alt
              text
            }
            open
          }
        }
        lynefit_faq_section {
          list {
            question
            answer {
              image
              mobile_image
              image_alt
              text
            }
            open
          }
        }
        lynehome_size {
          Male
          Female
        }
        lynehome_color {
          item
        }
        lynefit_size {
          Male
          Female
        }
        lynefit_color {
          item
        }
      }
    }
    allDirectusLynefitTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        size_chart_text
        size_popup_text
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
      }
    }
    allDirectusLyneHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        size_chart_text
        size_popup_text
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_material_info
        product_add_to_cart_button
        size {
          item
        }
        color {
          item
        }
        gender {
          item
        }
        male_size_chart {
          data {
            url
          }
        }
        female_size_chart {
          data {
            url
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        price_details
        size_popup_text
        language
        my_custom_size_text
        one_size_system_text
        morphology_text
        i_calculate_text
        most_suited_text
        edit_my_information_text
        return_text
        alma_popup_text
        combo_section {
          single_product_text
          offer_title
          offer_text
        }
        combo_tooltip
      }
    }
    allDirectusLyneupNewPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        male_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text {
          men_title
          women_title
          subtitle
          men_image
          women_image
          left_text
          right_text
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusLyneup2BPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        innovation_section {
          medical_image
        }
      }
    }
    allDirectusReviewTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          product
          name
          stars
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
        lyne_testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lyne" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneUp))
